const actions = {
  SET_STATE: 'customer/SET_STATE',
  SET_ERROR_STATE: 'customer/SET_ERROR_STATE',
  SHOW_DELETE: 'customer/SHOW_DELETE',
  HIDE_DELETE: 'customer/HIDE_DELETE',
  SHOW_BLOCK: 'customer/SHOW_BLOCK',
  HIDE_BLOCK: 'customer/HIDE_BLOCK',

  LOAD: 'customer/LOAD',
  REMOVE: 'customer/REMOVE',
  BLOCK: 'customer/BLOCK',
}

export default actions
