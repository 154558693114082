import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  importExternal,
  issue,
  load,
  loadDigital,
  loadRedeemedSummary,
  loadSalesSummary,
  loadTransactions,
  redeem,
  reload,
  resend,
} from 'api/gift-card'
import { history } from 'index'
import actions from './actions'

export function* LOAD() {
  const { response } = yield call(load)
  if (response) {
    const { gift_issued, gift_redeemed, gift_unredeemed, gift_cards, payment_mode } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        giftIssued: gift_issued,
        giftRedeemed: gift_redeemed,
        giftUnredeemed: gift_unredeemed,
        data: gift_cards,
        paymentMode: payment_mode,
      },
    })
  }
}

export function* LOAD_DIGITAL() {
  const { response } = yield call(loadDigital)
  if (response) {
    const { reserved_gift_card } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        reservedGiftCard: reserved_gift_card,
      },
    })
  }
}

export function* LOAD_TRANSACTIONS({ payload }) {
  const { giftCardId } = payload
  const { response } = yield call(loadTransactions, giftCardId)
  if (response) {
    const { transactions, transaction_types } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        transactions,
        transactionTypes: transaction_types,
      },
    })
  }
}

export function* LOAD_SALES_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingSalesSummary: true,
    },
  })
  const { response } = yield call(loadSalesSummary, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingSalesSummary: false,
    },
  })
  if (response) {
    const { transactions } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        salesSummary: {
          data: transactions,
        },
      },
    })
  }
}

export function* LOAD_REDEEMED_SUMMARY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRedeemedSummary: true,
    },
  })
  const { response } = yield call(loadRedeemedSummary, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loadingRedeemedSummary: false,
    },
  })
  if (response) {
    const { transactions } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        redeemedSummary: {
          data: transactions,
        },
      },
    })
  }
}

export function* ISSUE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      issuing: true,
    },
  })
  const { response } = yield call(issue, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      issuing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayIssueDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* RELOAD({ payload }) {
  const { reloadData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      reloading: true,
    },
  })
  const { response } = yield call(reload, reloadData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      reloading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayReloadDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* RESEND({ payload }) {
  const { giftCardId, resendData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      resending: true,
    },
  })
  const { response } = yield call(resend, giftCardId, resendData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resending: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayResendDrawer: false,
      },
    })
  }
}

export function* REDEEM({ payload }) {
  const { redeemData } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: true,
    },
  })
  const { response } = yield call(redeem, redeemData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      redeeming: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayRedeemDrawer: false,
      },
    })
    yield call(LOAD)
  }
}

export function* IMPORT_EXTERNAL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      importing: true,
    },
  })
  const { response } = yield call(importExternal, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      importing: false,
    },
  })
  if (response) {
    yield history.push('/manage/menu')
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeLatest(actions.LOAD_DIGITAL, LOAD_DIGITAL),
    takeLatest(actions.LOAD_TRANSACTIONS, LOAD_TRANSACTIONS),
    takeLatest(actions.LOAD_SALES_SUMMARY, LOAD_SALES_SUMMARY),
    takeLatest(actions.LOAD_REDEEMED_SUMMARY, LOAD_REDEEMED_SUMMARY),
    takeLatest(actions.ISSUE, ISSUE),
    takeLatest(actions.RELOAD, RELOAD),
    takeLatest(actions.RESEND, RESEND),
    takeLatest(actions.REDEEM, REDEEM),
    takeLatest(actions.IMPORT_EXTERNAL, IMPORT_EXTERNAL),
  ])
}
