import moment from 'moment'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getOrders, getOrderDetails, cancelOrder, getFutureOrders } from 'api/orderhistory'
import actions from './actions'

export function* GET_ORDERS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(getOrders, payload)
  const { orders } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: orders,
      loading: false,
    },
  })
}

export function* GET_ORDER_DETAILS({ payload }) {
  const { response } = yield call(getOrderDetails, payload)
  const { order, isNew = true } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      selectedData: order,
      isNew,
    },
  })
}

export function* GET_FUTURE_ORDERS(data) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(getFutureOrders, data.payload)
  const { orders } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: orders,
      loading: false,
    },
  })
}

export function* CANCEL_ORDER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(cancelOrder, payload.order.id)

  const { order } = response.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      selectedData: order,
    },
  })
  if (payload.display) {
    yield call(
      GET_FUTURE_ORDERS,
      {
        payload: {
          from: moment(payload.dateValue.sdate).format('YYYY-MM-DD'),
          to: moment(payload.dateValue.edate).format('YYYY-MM-DD'),
          order_type: payload.display === 'List' ? payload.orderType : '',
          payment_type: payload.display === 'List' ? payload.paymentStatus : '',
        }
      }
    )
  } else {
    yield call(
      GET_ORDERS,
      {
        payload: {
          from: moment(payload.dateValue.sdate).format('YYYY-MM-DD'),
          to: moment(payload.dateValue.edate).format('YYYY-MM-DD'),
        }
      }
    )
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_ORDERS, GET_ORDERS),
    takeLatest(actions.GET_ORDER_DETAILS, GET_ORDER_DETAILS),
    takeLatest(actions.CANCEL_ORDER, CANCEL_ORDER),
    takeLatest(actions.GET_FUTURE_ORDERS, GET_FUTURE_ORDERS),
  ])
}
