import actions from './actions'

const initialState = {
  data: null,
  selectedData: null,
  search: null,
  displayRemoveModal: false,
  displayBlockModal: false,
  removing: false,
  blocking: false,
  errors: {
    update: null,
  },
}

export default function customerReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter((o) => action.payload._id !== o._id),
      }
    case actions.BLOCK_DATA:
      return {
        ...state,
        displayBlockModal: false,
        data: state.data.filter((o) => action.payload._id !== o._id),
      }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.customer, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    case actions.SHOW_BLOCK:
      return { ...state, selectedData: action.payload.customer, displayBlockModal: true }
    case actions.HIDE_BLOCK:
      return { ...state, displayBlockModal: false }
    default:
      return state
  }
}
