const actions = {
  SET_STATE: 'category/SET_STATE',
  CREATE_CATEGORY: 'category/CREATE_CATEGORY',
  UPDATE_CATEGORY: 'category/UPDATE_CATEGORY',
  GET_CATEGORIES: 'category/GET_CATEGORIES',
  SHOW_CREATE: 'category/SHOW_CREATE',
  HIDE_CREATE: 'category/HIDE_CREATE',
  SHOW_UPDATE: 'category/SHOW_UPDATE',
  HIDE_UPDATE: 'category/HIDE_UPDATE',
  SHOW_DELETE: 'category/SHOW_DELETE',
  HIDE_DELETE: 'category/HIDE_DELETE',
  REMOVE: 'category/REMOVE',
  UPDATE_SORTING: 'category/UPDATE_SORTING',
  CLONE_CATEGORY: 'category/CLONE_CATEGORY',
}

export default actions
