import apiClient from 'services/axios'

const BASE_URL = 'location/delivery-zone'
const BASE_URL2 = 'location/catering-delivery-zone'

export async function load() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addzone(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updatezone(payload, del_id) {
  delete payload.delivery_id
  return apiClient
    .put(`${BASE_URL}?delivery_id=${del_id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deletezone(del_id) {
  return apiClient
    .delete(`${BASE_URL}?delivery_id=${del_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addcateringzone(payload) {
  return apiClient
    .post(`${BASE_URL2}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updatecateringzone(payload, del_id) {
  delete payload.delivery_id
  return apiClient
    .put(`${BASE_URL2}?delivery_id=${del_id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deletecateringzone(del_id) {
  return apiClient
    .delete(`${BASE_URL2}?delivery_id=${del_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
