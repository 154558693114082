import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  createTimesheets,
  deleteTimesheet,
  getsalesreport,
  getStaffs,
  getTimesheetReports,
  getTimesheets,
  getDeliveryReports,
  getDoordashReports,
} from 'api/reports'
import actions from './actions'

export function* GET_SALES_SUMMARY(payload) {
  const { response } = yield call(getsalesreport, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      reportSummary: response.data.sales_summary,
    },
  })
}

export function* GET_TIME_SHEET_REPORTS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(getTimesheetReports, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      timesheet_reports: response.data.timesheets,
      loading: false,
    },
  })
}

export function* GET_TIME_SHEETS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(getTimesheets, payload)
  if (response && response.data) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        timesheets: response.data.timesheets,
        loading: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* CREATE_TIME_SHEETS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  yield call(createTimesheets, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
}

export function* DELETE_TIME_SHEET(payload) {
  const { response } = yield call(deleteTimesheet, payload)
  const { date } = payload.payload
  if (response) {
    yield put({
      type: actions.GET_TIME_SHEETS,
      payload: {
        date,
      },
    })
  }
}

export function* GET_STAFFS() {
  const { response } = yield call(getStaffs)
  yield put({
    type: actions.SET_STATE,
    payload: {
      staffs: response.data.staffs,
    },
  })
}

export function* GET_DELIVERY_REPORTS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(getDeliveryReports, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      delivery_reports: response.data.staffs,
      loading: false,
    },
  })
}

export function* GET_DOORDASH_REPORTS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(getDoordashReports, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      doordash_reports: response.data.reports,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeLatest(actions.GET_SALES_SUMMARY, GET_SALES_SUMMARY)])
  yield all([takeLatest(actions.GET_TIME_SHEET_REPORTS, GET_TIME_SHEET_REPORTS)])
  yield all([takeLatest(actions.DELETE_TIME_SHEET, DELETE_TIME_SHEET)])
  yield all([takeLatest(actions.GET_TIME_SHEETS, GET_TIME_SHEETS)])
  yield all([takeLatest(actions.CREATE_TIME_SHEETS, CREATE_TIME_SHEETS)])
  yield all([takeLatest(actions.GET_STAFFS, GET_STAFFS)])
  yield all([takeLatest(actions.GET_DELIVERY_REPORTS, GET_DELIVERY_REPORTS)])
  yield all([takeLatest(actions.GET_DOORDASH_REPORTS, GET_DOORDASH_REPORTS)])
}
