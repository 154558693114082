import apiClient from 'services/axios'
import apiFormDataClient from 'services/axios-formdata'

const BASE_URL = 'order/history'
const BASE_URL_1 = 'order/future_order'
const BASE_URL2 = 'order'

export async function getOrders(payload) {
  const { from, to } = payload.payload
  return apiClient
    .get(`${BASE_URL}?from=${from}&to=${to}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getOrderDetails(payload) {
  return apiClient
    .get(`${BASE_URL2}/${payload.id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cancelOrder(id) {
  return apiFormDataClient
    .get(`${BASE_URL2}/${id}/cancel`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getFutureOrders(payload) {
  return apiClient
    .post(`${BASE_URL_1}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}