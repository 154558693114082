import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { listPlans, getPlan, changePlan, listInvoices } from 'api/subscriptions'
import actions from './actions'
// import userActions from '../user/actions'

export function* GET_PLANS() {
  const { response } = yield call(listPlans)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        planDetail: response.data
      },
    })
  }
}

export function* GET_INVOICES() {
  const { response } = yield call(listInvoices)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoices: response?.data?.invoices || []
      },
    })
  }
}

export function* GET_SUBSCRIPTION() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(getPlan)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        subscription: response.data.subscription
      },
    })
  }
}

export function* CHANGE_PLAN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: true,
    },
  })
  const { response } = yield call(changePlan, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      changing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        subscription: response.data?.subscription
      },
    })
    // yield put({
    //   type: actions.SET_STATE,
    //   payload: {
    //     subscription: response.data
    //   },
    // })
    // yield put({
    //   type: actions.GET_SUBSCRIPTION,
    // })
    // yield put({
    //   type: userActions.LOAD_CURRENT_ACCOUNT,
    // })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_PLANS, GET_PLANS),
    takeLatest(actions.GET_SUBSCRIPTION, GET_SUBSCRIPTION),
    takeEvery(actions.CHANGE_PLAN, CHANGE_PLAN),
    takeEvery(actions.GET_INVOICES, GET_INVOICES),
  ])
}
