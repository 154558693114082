import actions from './actions'

const initialState = {
  planDetail: null,
  subscription: null,
  invoices: [],
  loading: false,
  changing: false
}

export default function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
