import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { getUserMenuData } from 'services/menu'
import actions from './actions'
import {
  selectCanAccessSetup,
  selectCanManageCoupon,
  selectCanManageCustomer,
  selectCanManageLocation,
  selectCanManageUser,
  selectCanRunReport,
  selectCanAccessStoreDesign,
  selectCanAccessCatering,
} from '../user/selectors'

export function* SET_DATA() {
  const menuData = yield call(getUserMenuData)
  const canManageCustomer = yield select(selectCanManageCustomer)
  const canManageUser = yield select(selectCanManageUser)
  const canManageLocation = yield select(selectCanManageLocation)
  const canManageCoupon = yield select(selectCanManageCoupon)
  const canRunReport = yield select(selectCanRunReport)
  const canAccessSetup = yield select(selectCanAccessSetup)
  const CanAccessStoreDesign = yield select(selectCanAccessStoreDesign)
  const CanAccessCatering = yield select(selectCanAccessCatering)
  if (!canAccessSetup) menuData.splice(menuData.map((o) => o.key).indexOf('setup'))
  if (!canRunReport) menuData.splice(menuData.map((o) => o.key).indexOf('reports'))
  if (!canManageCustomer) menuData.splice(menuData.map((o) => o.key).indexOf('manage-customers'), 1)
  if (!canManageUser) menuData.splice(menuData.map((o) => o.key).indexOf('manage-users'), 1)
  if (!canManageLocation) menuData.splice(menuData.map((o) => o.key).indexOf('manage-location'), 1)
  if (!canManageCoupon) menuData.splice(menuData.map((o) => o.key).indexOf('manage-coupons'), 1)
  if (!CanAccessStoreDesign) menuData.splice(menuData.map((o) => o.key).indexOf('store-design'), 1)
  if (!CanAccessCatering) menuData.splice(menuData.map((o) => o.key).indexOf('catering'), 1)
  yield put({
    type: actions.SET_STATE,
    payload: {
      menuData,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SET_DATA, SET_DATA),
    SET_DATA(), // run once on app load to fetch menu data
  ])
}
