const actions = {
  SET_STATE: 'setting/SET_STATE',
  GET_STORE: 'setting/GET_STORE',
  GET_ORDER_SETTINGS: 'setting/GET_ORDER_SETTINGS',
  GET_CATERING_SETTINGS: 'setting/GET_CATERING_SETTINGS',
  GET_STORE_DESIGN: 'setting/GET_STORE_DESIGN',
  UPDATE_STORE_DESIGN: 'setting/UPDATE_STORE_DESIGN',
  UPDATE_STORE: 'setting/UPDATE_STORE',
  UPDATE_STORE_INFO: 'setting/UPDATE_STORE_INFO',

  ADD_STORE_HOURS: 'setting/ADD_STORE_HOURS',
  ADD_OPENING_HOUR: 'setting/ADD_OPENING_HOUR',
  EDIT_OPENING_HOUR: 'setting/EDIT_OPENING_HOUR',
  DELETE_OPENING_HOUR: 'setting/DELETE_OPENING_HOUR',

  ADD_PICKUP_HOUR: 'setting/ADD_PICKUP_HOUR',
  EDIT_PICKUP_HOUR: 'setting/EDIT_PICKUP_HOUR',
  DELETE_PICKUP_HOUR: 'setting/DELETE_PICKUP_HOUR',
  DELETE_ALL_PICKUP_HOUR: 'setting/DELETE_ALL_PICKUP_HOUR',

  ADD_DELIVERY_HOUR: 'setting/ADD_DELIVERY_HOUR',
  EDIT_DELIVERY_HOUR: 'setting/EDIT_DELIVERY_HOUR',
  DELETE_DELIVERY_HOUR: 'setting/DELETE_DELIVERY_HOUR',
  DELETE_ALL_DELIVERY_HOUR: 'setting/DELETE_ALL_DELIVERY_HOUR',

  UPDATE_ONLINE_ORDER_SETTINGS: 'setting/UPDATE_ONLINE_ORDER_SETTINGS',
  UPDATE_CATERING_SETTINGS: 'setting/UPDATE_CATERING_SETTINGS',
  CLOSEADDSECTION: 'setting/CLOSEADDSECTION',

  GET_WEBSITE_MANAGER: 'setting/GET_WEBSITE_MANAGER',
  SAVE_WEBSITE_MANAGER: 'setting/SAVE_WEBSITE_MANAGER',
  SAVE_GALLERY: 'setting/SAVE_GALLERY',
  SAVE_POPUP: 'setting/SAVE_POPUP',
  SAVE_SEO: 'setting/SAVE_SEO',
  DELETE_POPUP_IMAGE: 'setting/DELETE_POPUP_IMAGE',
  DELETE_GALLERY_IMAGE: 'setting/DELETE_GALLERY_IMAGE',
  ACTIVE_WEBSITE_POPUP: 'setting/ACTIVE_WEBSITE_POPUP',
  INACTIVE_WEBSITE_POPUP: 'setting/INACTIVE_WEBSITE_POPUP',
  SAVE_MENU_IMAGE: 'setting/SAVE_MENU_IMAGE',
  DELETE_MENU_IMAGE: 'setting/DELETE_MENU_IMAGE',

  ADD_PROMOTION_URL: 'setting/ADD_PROMOTION_URL',
  HIDE_PROMOTION_URL: 'setting/HIDE_PROMOTION_URL',
  UPDATE_PROMOTION_URL: 'setting/UPDATE_PROMOTION_URL',

  SET_ERROR_STATE: 'setting/SET_ERROR_STATE',
  SHOW_CREATE: 'setting/SHOW_CREATE',
  HIDE_CREATE: 'setting/HIDE_CREATE',
  SHOW_UPDATE: 'setting/SHOW_UPDATE',
  HIDE_UPDATE: 'setting/HIDE_UPDATE',
  SHOW_DELETE: 'setting/SHOW_DELETE',
  HIDE_DELETE: 'setting/HIDE_DELETE',
  EDIT_DATA: 'setting/EDIT_DATA',
  REMOVE_DATA: 'setting/REMOVE_DATA',

  ADD_BLOCKED_DATE: 'setting/ADD_BLOCKED_DATE',
  EDIT_BLOCKED_DATE: 'setting/EDIT_BLOCKED_DATE',
  DELETE_BLOCKED_DATE: 'setting/DELETE_BLOCKED_DATE',
}

export default actions
