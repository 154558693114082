const actions = {
  SET_STATE: 'catering-delivery-radius/SET_STATE',
  UPDATE_MAP_SHAPE: 'catering-delivery-radius/UPDATE_MAP_SHAPE',
  UPDATE_FORM_VALUES: 'catering-delivery-radius/UPDATE_FORM_VALUES',
  UPDATE_CIRCLE_RADIUS: 'catering-delivery-radius/UPDATE_CIRCLE_RADIUS',
  UPDATE_CIRCLE_CENTER: 'catering-delivery-radius/UPDATE_CIRCLE_CENTER',
  UPDATE_POLYGON_COORD: 'catering-delivery-radius/UPDATE_POLYGON_COORD',
  GET_DELIVERY_ZONES: 'catering-delivery-radius/GET_DELIVERY_ZONES',
  ADD_ZONE: 'catering-delivery-radius/ADD_ZONE',
  UPDATE_ZONE: 'catering-delivery-radius/UPDATE_ZONE',
  DELETE_ZONE: 'catering-delivery-radius/DELETE_ZONE',
}

export default actions
