import actions from './actions'

const initialState = {
  data: null,
  selectedData: null,
  loading: false,

  search: null,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,

  errors: {
    create: null,
    update: null,
  },
}

export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_DATA:
      return {
        ...state,
        data: [...state.data, action.payload.data],
      }
    case actions.EDIT_DATA:
      return {
        ...state,
        displayUpdateDrawer: false,
        data: state.data.map((o) => {
          if (action.payload.data._id === o._id) {
            return action.payload.data
          }
          return o
        }),
      }
    case actions.REMOVE_DATA:
      return {
        ...state,
        displayRemoveModal: false,
        data: state.data.filter((o) => action.payload._id !== o._id),
      }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, displayAddDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.staff, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.staff, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    default:
      return state
  }
}
