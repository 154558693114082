const actions = {
  SET_STATE: 'reports/SET_STATE',
  GET_SALES_SUMMARY: 'reports/GET_SALES_SUMMARY',
  GET_TIME_SHEET_REPORTS: 'reports/GET_TIME_SHEET_REPORTS',
  GET_TIME_SHEETS: 'reports/GET_TIME_SHEETS',
  CREATE_TIME_SHEETS: 'reports/CREATE_TIME_SHEETS',
  DELETE_TIME_SHEET: 'reports/DELETE_TIME_SHEET',
  GET_STAFFS: 'reports/GET_STAFFS',
  GET_DELIVERY_REPORTS: 'reports/GET_DELIVERY_REPORTS',
  GET_DOORDASH_REPORTS: 'reports/GET_DOORDASH_REPORTS',
}

export default actions
