import apiFormDataClient from 'services/axios-formdata'
import apiClient from 'services/axios'

const BASE_URL = 'item'

export async function createItem({ payload }) {
  return apiFormDataClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updateItem({ payload }) {
  return apiFormDataClient
    .put(`${BASE_URL}/${payload.id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteItem(_id) {
  return apiClient
    .delete(`${BASE_URL}/${_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function sortItems({ payload }) {
  return apiClient
    .post(`${BASE_URL}/sort`, {
      items: payload.items,
      category_id: payload.category_id,
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cloneItem({ payload }) {
  return apiClient
    .post(`${BASE_URL}/${payload.id}/clone/`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getItems() {
  return apiClient
    .get(`${BASE_URL}/sync`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default {
  createItem,
  updateItem,
  deleteItem,
  sortItems,
  cloneItem,
  getItems,
}
