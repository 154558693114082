import actions from './actions'

const initialState = {
  // data: {
  //   new: {
  //     name: 'New',
  //     items: [],
  //   },
  //   confirmedAsap: {
  //     name: 'Confirmed (ASAP)',
  //     items: [],
  //   },
  //   confirmedUpcoming: {
  //     name: 'Confirmed (Upcoming)',
  //     items: [],
  //   },
  //   readyForPickup: {
  //     name: 'Ready for Pickup',
  //     items: [],
  //   },
  //   onDelivery: {
  //     name: 'On Delivery',
  //     items: [],
  //   },
  //   completed: {
  //     name: 'Completed',
  //     items: [],
  //   },
  // },
  searchcloseorder: null,
  data: null,
  selectedData: null,
  search: null,
  loading: false,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,
  isNew: true,
}

export default function orderhistoryReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
