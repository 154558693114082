const actions = {
  SET_STATE: 'gift-card/SET_STATE',
  SET_SALES_SUMMARY_SEARCH: 'gift-card/SET_SALES_SUMMARY_SEARCH',
  SET_REDEEMED_SUMMARY_SEARCH: 'gift-card/SET_REDEEMED_SUMMARY_SEARCH',
  EDIT_DATA: 'gift-card/EDIT_DATA',

  LOAD: 'gift-card/LOAD',
  LOAD_DIGITAL: 'gift-card/LOAD_DIGITAL',
  LOAD_TRANSACTIONS: 'gift-card/LOAD_TRANSACTIONS',
  LOAD_SALES_SUMMARY: 'gift-card/LOAD_SALES_SUMMARY',
  LOAD_REDEEMED_SUMMARY: 'gift-card/LOAD_REDEEMED_SUMMARY',
  ISSUE: 'gift-card/ISSUE',
  RELOAD: 'gift-card/RELOAD',
  RESEND: 'gift-card/RESEND',
  REDEEM: 'gift-card/REDEEM',
  IMPORT_EXTERNAL: 'gift-card/IMPORT_EXTERNAL',
}

export default actions
