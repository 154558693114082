import apiClient from 'services/axios'

const BASE_URL = 'website-widget'

export async function updateWidget(payload) {
  console.log('payload', payload);
  return apiClient
    .put(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getWidget() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getWidgetScript() {
  return apiClient
    .get(`${BASE_URL}/embed-script`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}