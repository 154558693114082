import { all, call, put, takeEvery } from 'redux-saga/effects'
import { getWidget, updateWidget, getWidgetScript } from 'services/website-widget'
import onboardStatusKeys from 'pages/admin/getting-started/status'
import actions from './actions'
import userActions from '../user/actions'

export function* GET_WEBSITE_WIDGET() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true
    }
  })

  const { response } = yield call(getWidget)
  yield put({
    type: actions.SET_STATE,
    payload: response.data,
  })

  yield put({
    type: actions.GET_WIDGET_SCRIPT
  })

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  })
}


export function* GET_WIDGET_SCRIPT() {
  const { response } = yield call(getWidgetScript)
  yield put({
    type: actions.SET_STATE,
    payload: {
      widgetScript: response?.data?.website_widget_script
    },
  })
}

export function* UPDATE_WEBSITE_WIDGET({ payload }) {
  console.log('payload1', payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true
    }
  })
  const { response } = yield call(updateWidget, payload)
  if (response) {
    yield put({
      type: actions.GET_WEBSITE_WIDGET
    })
    yield put({
      type: userActions.UPDATE_ONBOARD_STATUS,
      payload: onboardStatusKeys.websiteWidget,
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false
    }
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_WEBSITE_WIDGET, GET_WEBSITE_WIDGET),
    takeEvery(actions.GET_WIDGET_SCRIPT, GET_WIDGET_SCRIPT),
    takeEvery(actions.UPDATE_WEBSITE_WIDGET, UPDATE_WEBSITE_WIDGET)
  ])
}