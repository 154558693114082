import actions from './actions'

const initialState = {
  "theme_color": "",
  "text_color": "",
  "placement": "",
  "layout": "",
  "text": "",
  "icon": "",
  "loading": false
}

export default function websiteWidgetReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
