import { all, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'

export function* SET_FLOOR_PLAN({ payload: params }) {
  const { rooms, selectedRoom, elements } = params;
  // console.log('selectedRoom', selectedRoom);
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const payload = {
    loading: false,
  }
  if (rooms) {
    payload.rooms = rooms
  }
  if (selectedRoom) {
    payload.selectedRoom = selectedRoom
  }
  if (elements) {
    payload.elements = elements
  }
  
  yield put({
    type: actions.SET_STATE,
    payload
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.SET_FLOOR_PLAN, SET_FLOOR_PLAN),
  ])
}
