const actions = {
  SET_STATE: 'deliveryradius/SET_STATE',
  UPDATE_MAP_SHAPE: 'deliveryradius/UPDATE_MAP_SHAPE',
  UPDATE_FORM_VALUES: 'deliveryradius/UPDATE_FORM_VALUES',
  UPDATE_ADDRESS_VALUES: 'deliveryradius/UPDATE_ADDRESS_VALUES',
  UPDATE_CIRCLE_RADIUS: 'deliveryradius/UPDATE_CIRCLE_RADIUS',
  UPDATE_CIRCLE_CENTER: 'deliveryradius/UPDATE_CIRCLE_CENTER',
  UPDATE_POLYGON_COORD: 'deliveryradius/UPDATE_POLYGON_COORD',
  GET_DELIVERY_ZONES: 'deliveryradius/GET_DELIVERY_ZONES',
  ADD_ZONE: 'deliveryradius/ADD_ZONE',
  UPDATE_ZONE: 'deliveryradius/UPDATE_ZONE',
  DELETE_ZONE: 'deliveryradius/DELETE_ZONE',
}

export default actions
