import { all, call, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import { getOrders, updateOrder, getOrderDetails } from 'services/order'
import actions from './actions'

export function* GET_ORDERS(payload) {
  const { response } = yield call(getOrders, payload)

  // convert to column structure
  const { orders } = response.data

  const data = {
    new: {
      name: 'New',
      items: orders.open_orders,
    },
    confirmedAsap: {
      name: 'Confirmed (Now)',
      items: orders.confirmed_order_asap,
    },
    confirmedUpcoming: {
      name: 'Confirmed (Upcoming)',
      items: orders.confirmed_scheduled,
    },
    readyForPickup: {
      name: 'Ready for Pickup',
      items: orders.ready_for_pickup,
    },
    onDelivery: {
      name: 'On Delivery',
      items: orders.on_delivery,
    },
    completed: {
      name: 'Completed',
      items: orders.completed,
    },
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      data,
    },
  })
}

export function* UPDATE_STATUS({ payload }) {
  // const { oldColumns } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(updateOrder, payload.order.id, {
    order_status: payload.status,
    preparation_time: payload.estimated_time,
  })

  const { order } = payload

  order.status = payload.status

  console.log(response)

  if (!response.data._id) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        selectedData: response.data,
      },
    })

    const business = store.get('user.business')
    // const location = store.get('user.location')
    const payload1 = {
      payload: {
        business_id: business._id,
        // location_id: location._id,
      },
    }
    yield call(GET_ORDERS, payload1)
  }
}

export function* UPDATE_ORDER({ payload }) {
  // const { oldColumns } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
      data: payload.columns,
    },
  })

  console.log(payload)

  const { response } = yield call(updateOrder, payload.order.id, { order_status: payload.status })

  if (!response.data._id) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: payload.oldColumns,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
      },
    })
  }
}

export function* GET_DETAILS({ payload }) {
  const { response } = yield call(getOrderDetails, payload)

  // convert to column structure
  const { order } = response.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      selectedData: order,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.UPDATE_STATUS, UPDATE_STATUS),
    takeLatest(actions.UPDATE_ORDER, UPDATE_ORDER),
    takeLatest(actions.GET_ORDERS, GET_ORDERS),
    takeLatest(actions.GET_DETAILS, GET_DETAILS),
  ])
}
