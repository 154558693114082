// import { notification } from 'antd'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getCategories } from 'services/category'
import { createItem, updateItem, deleteItem, sortItems, cloneItem } from 'services/item'
import store from 'store'
import onboardStatusKeys from 'pages/admin/getting-started/status'
import userActions from 'redux/user/actions'
import actions from './actions'

export function* CREATE_ITEM(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const status = yield call(createItem, payload)

  if (status?.response?.data) {
    const getCatPayload = {
      payload: {
        business_id: business._id,
      },
    }
    yield put({
      type: userActions.UPDATE_ONBOARD_STATUS,
      payload: onboardStatusKeys.rewardProgram,
    })
    const { response } = yield call(getCategories, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.categories,
        originalData: JSON.parse(JSON.stringify(response.data.categories)),
        displayAddDrawer: false,
        creating: false,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_ITEM(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(updateItem, payload)

  if (status?.response?.data) {
    const business = store.get('user.business')
    // const location = store.get('user.location')
    const getCatPayload = {
      payload: {
        business_id: business._id,
        // location_id: location._id,
      },
    }
    // notification.success({
    //   message: "Item updated successfully"
    // })
    const { response } = yield call(getCategories, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.categories,
        originalData: JSON.parse(JSON.stringify(response.data.categories)),
        displayUpdateDrawer: false,
        updating: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* SORT_ITEMS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(sortItems, payload)

  if (status) {
    const business = store.get('user.business')
    // const location = store.get('user.location')
    const getCatPayload = {
      payload: {
        business_id: business._id,
        // location_id: location._id,
      },
    }
    const { response } = yield call(getCategories, getCatPayload)
    if (response?.data) {
      yield put({
        type: actions.SET_STATE,
        payload: {
          loading: false,
          listing: false,
          data: response.data.categories,
          originalData: JSON.parse(JSON.stringify(response.data.categories)),
          displayUpdateDrawer: false,
          updating: false,
        },
      })
    }
  }
}

export function* GET_CATEGORIES(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true,
    },
  })

  const { response } = yield call(getCategories, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      listing: false,
      data: response.data.categories,
      originalData: JSON.parse(JSON.stringify(response.data.categories)),
      selectedData: null
    },
  })
}

export function* REMOVE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })

  const status = yield call(deleteItem, payload.payload._id)
  console.log(status)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const getCatPayload = {
    payload: {
      business_id: business._id,
      // location_id: location._id,
    },
  }
  const { response } = yield call(getCategories, getCatPayload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      data: response.data.categories,
      originalData: JSON.parse(JSON.stringify(response.data.categories)),
      displayRemoveModal: false,
      removing: false,
    },
  })
}

export function* CLONE_ITEM(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(cloneItem, payload)

  if (status?.response.data?.message) {
    const { response } = yield call(getCategories)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        originalData: JSON.parse(JSON.stringify(response.data.categories))
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CREATE_ITEM, CREATE_ITEM),
    takeLatest(actions.UPDATE_ITEM, UPDATE_ITEM),
    takeLatest(actions.GET_CATEGORIES, GET_CATEGORIES),
    takeLatest(actions.REMOVE, REMOVE),
    takeLatest(actions.SORT_ITEMS, SORT_ITEMS),
    takeLatest(actions.CLONE_ITEM, CLONE_ITEM),
  ])
}
