import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  addReservationHour,
  editReservationHour,
  removeReservationHour,
  reservation_setup,
  updateReservationSetup,
  addBlockedDate,
  editBlockedDate,
  removeBlockedDate,
} from 'api/reservation'
import actions from './actions'
// import userActions from '../user/actions'

export function* GET_RESERVATION_SETUP() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(reservation_setup)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    // response.data.location = response?.data?.location
    yield put({
      type: actions.SET_STATE,
      payload: {
        reservationSetupDetails: {
          reservation_interval: response?.data?.reservations_settings?.reservation_interval,
          minimum_time_to_book: response?.data?.reservations_settings?.minimum_time_to_book,
          maximum_time_to_book: response?.data?.reservations_settings?.maximum_time_to_book,
          meal_duration: response?.data?.reservations_settings?.meal_duration,
          is_restaurant_message: response?.data?.reservations_settings?.is_restaurant_message,
          restaurant_message: response?.data?.reservations_settings?.restaurant_message,
          cancellation_policy_message:
            response?.data?.reservations_settings?.cancellation_policy_message,
          theme_color: response?.data?.reservations_settings?.theme_color,
          background_image_url: response?.data?.reservations_settings?.background_image_url,
        },
        reservationHours: response?.data?.reservations_settings?.reservation_hours,
        blockDates: response?.data?.reservations_settings?.block_dates,
        depositSettings: response?.data?.reservations_settings?.deposit_settings,
        reservationURL: response?.data?.reservation_url,
      },
    })
  }
}

export function* UPDATE_RESERVATION_SETUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })

  const { updatedData } = payload
  const { response } = yield call(updateReservationSetup, updatedData)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        reservationSetupDetails: {
          reservation_interval: response?.data?.reservations_settings?.reservation_interval,
          minimum_time_to_book: response?.data?.reservations_settings?.minimum_time_to_book,
          maximum_time_to_book: response?.data?.reservations_settings?.maximum_time_to_book,
          meal_duration: response?.data?.reservations_settings?.meal_duration,
          is_restaurant_message: response?.data?.reservations_settings?.is_restaurant_message,
          restaurant_message: response?.data?.reservations_settings?.restaurant_message,
          cancellation_policy_message:
            response?.data?.reservations_settings?.cancellation_policy_message,
          theme_color: response?.data?.reservations_settings?.theme_color,
          background_image_url: response?.data?.reservations_settings?.background_image_url,
        },
        depositSettings: response?.data?.reservations_settings?.deposit_settings,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* ADD_RESERVATION_HOUR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: 'opening',
    },
  })
  const { response } = yield call(addReservationHour, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        reservationHours: response?.data?.reservations_settings?.reservation_hours,
      },
    })
  }
}

export function* EDIT_RESERVATION_HOUR({ payload }) {
  const { type, hour_id, hours } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: hour_id,
    },
  })
  const { response } = yield call(editReservationHour, hour_id, { type, hours })
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        reservationHours: response?.data?.reservations_settings?.reservation_hours,
      },
    })
  }
}

export function* DELETE_RESERVATION_HOUR({ payload }) {
  const { hour_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: hour_id,
    },
  })
  const { response } = yield call(removeReservationHour, hour_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        removeHour: null,
        reservationHours: response?.data?.reservations_settings?.reservation_hours,
      },
    })
  }
}

export function* ADD_BLOCKED_DATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response, error } = yield call(addBlockedDate, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
      payload: {
        blockDates: response?.data?.reservations_settings?.block_dates,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* EDIT_BLOCKED_DATE({ payload }) {
  const { _id, block_dates } = payload
  console.log('block_dates', block_dates)
  yield put({
    type: actions.SET_STATE,
    payload: {
      update: true,
    },
  })
  const { response, error } = yield call(editBlockedDate, _id, block_dates)
  yield put({
    type: actions.SET_STATE,
    payload: {
      update: false,
    },
  })
  if (response) {
    yield put({
      type: actions.EDIT_DATA,
      payload: {
        blockDates: response?.data?.reservations_settings?.block_dates,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        update: errors,
      },
    })
  }
}

export function* DELETE_BLOCKED_DATE({ payload }) {
  const { _id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removeBlockedDate, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.REMOVE_DATA,
      payload: {
        blockDates: response?.data?.reservations_settings?.block_dates,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_RESERVATION_SETUP, GET_RESERVATION_SETUP),
    takeLatest(actions.UPDATE_RESERVATION_SETUP, UPDATE_RESERVATION_SETUP),
    takeLatest(actions.ADD_RESERVATION_HOUR, ADD_RESERVATION_HOUR),
    takeLatest(actions.EDIT_RESERVATION_HOUR, EDIT_RESERVATION_HOUR),
    takeLatest(actions.DELETE_RESERVATION_HOUR, DELETE_RESERVATION_HOUR),
    takeLatest(actions.ADD_BLOCKED_DATE, ADD_BLOCKED_DATE),
    takeLatest(actions.EDIT_BLOCKED_DATE, EDIT_BLOCKED_DATE),
    takeLatest(actions.DELETE_BLOCKED_DATE, DELETE_BLOCKED_DATE),
  ])
}
