const actions = {
  SET_STATE: 'item/SET_STATE',
  CREATE_ITEM: 'item/CREATE_ITEM',
  UPDATE_ITEM: 'item/UPDATE_ITEM',
  GET_CATEGORIES: 'item/GET_CATEGORIES',
  SHOW_CREATE: 'item/SHOW_CREATE',
  HIDE_CREATE: 'item/HIDE_CREATE',
  SHOW_UPDATE: 'item/SHOW_UPDATE',
  HIDE_UPDATE: 'item/HIDE_UPDATE',
  SHOW_DELETE: 'item/SHOW_DELETE',
  HIDE_DELETE: 'item/HIDE_DELETE',
  REMOVE: 'item/REMOVE',
  SORT_ITEMS: 'item/SORT_ITEMS',
  CLONE_ITEM: 'item/CLONE_ITEM',
}

export default actions
