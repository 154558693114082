import actions from './actions'

const initialState = {
  loading: false,
  updating: false,
  popUpImageAdding: false,
  popUpurlAdding: false,
  gallerybtndisabled: false,
  gallerybtnloading: false,
  removing: false,
  websiteSetupLoading: false,
  SocialMediaLoading: false,
  uploading: false,
  storeDetails: null,
  website_theme_color_info: null,
  contactDetails: null,
  updatingContactDetails: false,
  openingHours: null,
  pickupHourInfo: null,
  deliveryHourInfo: null,
  selectedHour: null,
  savingHour: null,
  removeHour: null,
  removingHour: null,
  hideaddbutton: false,
  showPickupRemove: false,
  showDeliveryRemove: false,
  holidays: null,
  data: null,
  data1: null,
  data2: null,
  geo_location: null,
  website_manager: null,
  WebsiteManagerPreviewUrl: null,
  preview_url: null,
  store_name: null,
  address: null,
  city: null,
  state: null,
  postal_code: null,
  country: null,
  timezone: null,
  contact_name: null,
  contact_phone_number: null,
  contact_alt_phone_number: null,
  businesshrinfo: null,
  pickuphrinfo: null,
  deliveryhrinfo: null,
  display_menu_picture: null,
  display_allergy_ingredients: null,
  display_menu_preferences: null,
  display_menu_calorie_count: null,
  is_pick_up_available: null,
  pick_up_asap: null,
  pick_up_scheduled: null,
  pick_up_count: null,
  pick_up_interval: null,
  pick_up_minimum_time_to_order: null,
  pick_up_maximum_order_in_days: null,
  pick_up_cut_off_time_for_next_day: null,
  average_pick_up_time: null,
  pick_up_accept_tip: null,
  is_delivery_available: null,
  delivery_asap: null,
  delivery_scheduled: null,
  delivery_count: null,
  delivery_interval: null,
  delivery_minimum_time_to_order: null,
  delivery_maximum_order_in_days: null,
  delivery_cut_off_time_for_next_day: null,
  average_delivery_time: null,
  delivery_accept_tip: null,
  pick_up_card_online: null,
  pick_up_card_pay_at_counter: null,
  card_pay_at_counter: null,
  pick_up_cash: null,
  delivery_card_online: null,
  delivery_card_pay_on_delivery: null,
  delivery_cash: null,
  verify_age_before_order: null,
  minimum_age: null,
  age_verification_message: null,
  banner_image: '',
  theme_color: '',
  preferred_language: null,
  logo: '',
  slug: null,
  print_receipt_copy: null,
  number_of_copies: null,
  printer_name: null,
  notify_allergy_warning: null,
  allergy_warning_message: null,
  email_alert: null,
  dinein_email_alert: false,
  auto_confirm: null,
  print_clover_order: null,
  sms_alert: null,
  call_alert: null,
  notify_not_confirmed: null,
  email: null,
  phone_number: null,
  addopeninghrs: null,
  editopeninghrs: null,
  addpickuphrs: null,
  editpickuphrs: null,
  adddeliveryhrs: null,
  editdeliveryhrs: null,
  actiontype: null,
  creating: false,
  endhourtime: null,
  canShowPickUpHours: false,
  canDisplayOpeningHourForm: false,
  isSubmittingOpeningHour: false,
  canDisplayPickupHourForm: false,
  isSubmittingPicupHour: false,
  candDIsplayDeliveryHourForm: false,
  isSubmittingDeliveryHour: false,
  CanShowPickupDiv: false,
  CanShowDeliveryDiv: false,
  displayPromotionUrlModal: false,
  form_name: null,
  showPickupHour: false,
  showDeliveryHour: false,
  seperate_deal_section: true,
  is_dine_in_available: false,
  item_availability: false,
  grid_view: false,
  display_category_image: false,
  show_savings: false,
  default_pick_up_tip: 15,
  default_delivery_tip: 15,
  allow_service_fees: false,
  service_fee: 0.99,
  cateringSetting: null,
  is_catering_delivery_zone_available: false,
  special_instructions: false,
  print_order_request_time: false,
  print_scheduled_order: 0,
  dine_in_accept_tip: false,
  default_dine_in_tip: 15,
  dine_in_customer_notes: '',
  theme_type: 'Light',
  verify_zip_code: true,
  number_sequence: false,
  order_special_instructions: true,
  disable_order_notes: false,
  display_verification_notes: true,
  show_phone_number: true,
  displayAddDrawer: false,
  displayUpdateDrawer: false,
  selectedData: null,
  displayRemoveModal: false,
  google_analytics_code: null,
  facebook_pixel_id: null,
  google_places_id: null,
  order_confirmation_message: null,
  pick_up_large_order: false,
  pick_up_subtotal: null,
  pick_up_extra_time: null,
  delivery_large_order: false,
  delivery_subtotal: null,
  delivery_extra_time: null,
  errors: {
    create: null,
    update: null,
  },
}

export default function settingReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.ADD_PROMOTION_URL:
      return { ...state, selectedData: action.payload, displayPromotionUrlModal: true }
    case actions.HIDE_PROMOTION_URL:
      return { ...state, displayPromotionUrlModal: false }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, ...action.payload, displayAddDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.blockedDate, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.blockedDate, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    case actions.EDIT_DATA:
      return {
        ...state,
        ...action.payload,
        displayUpdateDrawer: false,
      }
    case actions.REMOVE_DATA:
      return {
        ...state,
        ...action.payload,
        displayRemoveModal: false,
      }
    default:
      return state
  }
}
