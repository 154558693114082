import apiClient from 'services/axios'

const BASE_URL = 'setting'
const BASE_URL2 = 'business/setting'
const BASE_URL3 = 'catering-setting'
const BASE_URL_4 = 'location'
const BASE_URL_5 = 'pos-setting'
const GET_STORE_URL = '/business/info'
const ADD_OPE_HRS_URL = 'location/opening-hours'
const DEL_OPE_HRS_URL = 'location/opening-hours/business'
const DEL_PIC_HRS_URL = 'location/opening-hours/pickup'
const DEL_DEL_HRS_URL = 'location/opening-hours/delivery'
const DEL_ALL_PICKUP_HR_URL = 'location/opening-hours/pickup/all'
const DEL_ALL_DELIVERY_HR_URL = 'location/opening-hours/delivery/all'

export async function store_info() {
  return apiClient
    .get(`${GET_STORE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function order_settings() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function catering_settings() {
  return apiClient
    .get(`${BASE_URL3}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function store_design() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function storeupdate(payload) {
  return apiClient
    .patch(`${GET_STORE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addOpeningHour(payload) {
  return apiClient
    .post(`${ADD_OPE_HRS_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editOpeningHour(hourId, payload) {
  return apiClient
    .put(`${ADD_OPE_HRS_URL}?hours_id=${hourId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removeOpeningHour(hourId) {
  return apiClient
    .delete(`${DEL_OPE_HRS_URL}?hours_id=${hourId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addPickupHour(payload) {
  return apiClient
    .post(`${ADD_OPE_HRS_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editPickupHour(hourId, payload) {
  return apiClient
    .put(`${ADD_OPE_HRS_URL}?hours_id=${hourId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removePickupHour(hourId) {
  return apiClient
    .delete(`${DEL_PIC_HRS_URL}?hours_id=${hourId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addDeliveryHour(payload) {
  return apiClient
    .post(`${ADD_OPE_HRS_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editDeliveryHour(hourId, payload) {
  return apiClient
    .put(`${ADD_OPE_HRS_URL}?hours_id=${hourId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removeDeliveryHour(hourId) {
  return apiClient
    .delete(`${DEL_DEL_HRS_URL}?hours_id=${hourId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function onlineordersettingsupd(payload) {
  return apiClient
    .put(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cateringsettingsupd(payload) {
  return apiClient
    .put(`${BASE_URL3}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function storedesignupd(payload) {
  return apiClient
    .post(`${BASE_URL2}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteallpickuphr() {
  return apiClient
    .delete(`${DEL_ALL_PICKUP_HR_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
export async function deletealldeliveryhr() {
  return apiClient
    .delete(`${DEL_ALL_DELIVERY_HR_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addStoreHours(payload) {
  return apiClient
    .patch(`${ADD_OPE_HRS_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addHoliday(payload) {
  return apiClient
    .post(`${BASE_URL_4}/holidays`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editHoliday(HolidayId, payload) {
  return apiClient
    .put(`${BASE_URL_4}/holidays?holiday_id=${HolidayId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removeHoliday(HolidayId) {
  return apiClient
    .delete(`${BASE_URL_4}/holidays?holiday_id=${HolidayId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function pos_settings() {
  return apiClient
    .get(`${BASE_URL_5}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function save_pos_settings(payload) {
  return apiClient
    .put(`${BASE_URL_5}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}