import apiClient from 'services/axios'

const BASE_URL = 'manage-location'
const BASE_URL_2 = 'business'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function update(locationId, payload) {
  return apiClient
    .put(`${BASE_URL}/${locationId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function remove(locationId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${locationId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function search(email, payload) {
  return apiClient
    .get(`${BASE_URL_2}/search/${email}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function verify(code, payload) {
  return apiClient
    .get(`${BASE_URL}/verify/${code}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
