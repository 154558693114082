import actions from './actions'

const initialState = {
  giftIssued: null,
  giftRedeemed: null,
  giftUnredeemed: null,
  data: null,
  search: null,
  reservedGiftCard: null,
  paymentMode: null,
  transactions: null,
  transactionTypes: null,
  salesSummary: {
    data: null,
    search: null,
  },
  redeemedSummary: {
    data: null,
    search: null,
  },

  selectedData: null,
  issuing: false,
  reloading: false,
  resending: false,
  redeeming: false,
  importing: false,
  loadingSalesSummary: false,
  loadingRedeemedSummary: false,

  displayIssueDrawer: false,
  displayTransactionHistoryDrawer: false,
  displayReloadDrawer: false,
  displayResendDrawer: false,
  displayRedeemDrawer: false,
  displayUpdateDrawer: false,
}

export default function giftCardReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SALES_SUMMARY_SEARCH:
      return { ...state, salesReport: { ...state.salesSummary, search: action.payload } }
    case actions.SET_REDEEMED_SUMMARY_SEARCH:
      return { ...state, redeemedSummary: { ...state.redeemedSummary, search: action.payload } }
    case actions.EDIT_DATA:
      return { ...state, displayRedeemDrawer: false }
    default:
      return state
  }
}
