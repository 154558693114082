import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  addDeliveryHour,
  addOpeningHour,
  addPickupHour,
  editDeliveryHour,
  editOpeningHour,
  editPickupHour,
  onlineordersettingsupd,
  storedesignupd,
  order_settings,
  store_design,
  removeDeliveryHour,
  removeOpeningHour,
  removePickupHour,
  store_info,
  storeupdate,
  deleteallpickuphr,
  deletealldeliveryhr,
  catering_settings,
  cateringsettingsupd,
  addStoreHours,
  addHoliday,
  editHoliday,
  removeHoliday,
} from 'api/setting'
import {
  savewebsitemanager,
  saveseo,
  savegallery,
  getwebsitemanager,
  removepopup,
  delete_gallery_image,
  activewebsitepopup,
  inactivewebsitepopup,
  savepopup,
  savepopupurl,
  savemenu,
  delete_menu_image,
} from 'api/website'
import onboardStatusKeys from 'pages/admin/getting-started/status'
import actions from './actions'
import userActions from '../user/actions'

export function* GET_STORE() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(store_info)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const phone_number = response?.data?.location?.contact?.phone_number
    const alt_phone_number = response?.data?.user?.alt_phone_number
    // response.data.location = response?.data?.location
    // phone_number: response?.data?.location?.contact?.phone_number?.replace('+1', ''),
    yield put({
      type: actions.SET_STATE,
      payload: {
        storeDetails: {
          store_name: response?.data?.business?.name,
          type: response?.data?.business?.type,
          phone_number: phone_number?.substr(phone_number?.length - 10),
          address: response?.data?.location?.address?.street,
          city: response?.data?.location?.address?.city,
          state: response?.data?.location?.address?.state_province_details?.name,
          postal_code: response?.data?.location?.address?.zip_postal_code,
          country: response?.data?.location?.address?.country_details?.name,
          timezone: response?.data?.location?.timezone,
          slug: response?.data?.business?.slug,
          website: response?.data?.business?.website,
          location_name: response?.data?.business?.location_name,
        },
        contactDetails: {
          first_name: response?.data?.user.first_name,
          last_name: response?.data?.user?.last_name,
          email: response?.data?.user?.email,
          // phone_number: response?.data?.location?.user?.phone_number,
          alt_phone_number: alt_phone_number?.substr(alt_phone_number?.length - 10),
        },
        openingHours: response?.data?.location?.location_hours,
        pickupHourInfo: response?.data?.location?.pickup,
        deliveryHourInfo: response?.data?.location?.delivery,
        showPickupHour: response?.data?.business?.setting?.pick_up?.is_pick_up_available,
        showDeliveryHour: response?.data?.business?.setting?.delivery?.is_delivery_available,
        holidays: response?.data?.location?.holiday_hours,
      },
    })
  }
}

export function* UPDATE_STORE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingContactDetails: true,
    },
  })
  const { response } = yield call(storeupdate, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updatingContactDetails: false,
    },
  })
  if (response) {
    let payloadData
    if (response?.data?.user) {
      const alt_phone_number = response?.data?.user?.alt_phone_number
      payloadData = {
        contactDetails: {
          first_name: response?.data?.user.first_name,
          last_name: response?.data?.user?.last_name,
          alt_phone_number: alt_phone_number?.substr(alt_phone_number?.length - 10),
        },
      }
      yield put({
        type: actions.SET_STATE,
        payload: payloadData,
      })
    }
  }
}

export function* GET_ORDER_SETTINGS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(order_settings)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        display_menu_picture: response?.data?.setting?.menu_appearance?.display_menu_picture,
        display_allergy_ingredients:
          response?.data?.setting?.menu_appearance?.display_allergy_ingredients,
        display_menu_preferences:
          response?.data?.setting?.menu_appearance?.display_menu_preferences,
        display_menu_calorie_count:
          response?.data?.setting?.menu_appearance?.display_menu_calorie_count,
        is_pick_up_available: response?.data?.setting?.pick_up?.is_pick_up_available,
        pick_up_asap: response?.data?.setting?.pick_up?.pick_up_asap,
        pick_up_scheduled: response?.data?.setting?.pick_up?.pick_up_scheduled,
        pick_up_count: response?.data?.setting?.pick_up?.pick_up_count,
        pick_up_interval: response?.data?.setting?.pick_up?.pick_up_interval,
        pick_up_minimum_time_to_order:
          response?.data?.setting?.pick_up?.pick_up_minimum_time_to_order,
        pick_up_maximum_order_in_days:
          response?.data?.setting?.pick_up?.pick_up_maximum_order_in_days,
        pick_up_cut_off_time_for_next_day:
          response?.data?.setting?.pick_up?.pick_up_cut_off_time_for_next_day,
        average_pick_up_time: response?.data?.setting?.pick_up?.average_pick_up_time,
        pick_up_accept_tip: response?.data?.setting?.pick_up?.pick_up_accept_tip,
        default_pick_up_tip: response?.data?.setting?.pick_up?.default_pick_up_tip,
        is_delivery_available: response?.data?.setting?.delivery?.is_delivery_available,
        delivery_asap: response?.data?.setting?.delivery?.delivery_asap,
        delivery_scheduled: response?.data?.setting?.delivery?.delivery_scheduled,
        delivery_count: response?.data?.setting?.delivery?.delivery_count,
        delivery_interval: response?.data?.setting?.delivery?.delivery_interval,
        delivery_minimum_time_to_order:
          response?.data?.setting?.delivery?.delivery_minimum_time_to_order,
        delivery_maximum_order_in_days:
          response?.data?.setting?.delivery?.delivery_maximum_order_in_days,
        delivery_cut_off_time_for_next_day:
          response?.data?.setting?.delivery?.delivery_cut_off_time_for_next_day,
        average_delivery_time: response?.data?.setting?.delivery?.average_delivery_time,
        delivery_accept_tip: response?.data?.setting?.delivery?.delivery_accept_tip,
        default_delivery_tip: response?.data?.setting?.delivery?.default_delivery_tip,
        pick_up_card_online: response?.data?.setting?.payments?.pick_up?.card_online,
        pick_up_card_pay_at_counter:
          response?.data?.setting?.payments?.pick_up?.card_pay_at_counter,
        pick_up_cash: response?.data?.setting?.payments?.pick_up?.cash,
        delivery_card_online: response?.data?.setting?.payments?.delivery?.card_online,
        delivery_card_pay_on_delivery:
          response?.data?.setting?.payments?.delivery?.card_pay_on_delivery,
        delivery_cash: response?.data?.setting?.payments?.delivery?.cash,
        verify_age_before_order: response?.data?.setting?.age_verification?.verify_age_before_order,
        minimum_age: response?.data?.setting?.age_verification?.minimum_age,
        age_verification_message:
          response?.data?.setting?.age_verification?.age_verification_message,
        // banner_image: response?.data?.location?.settings?.banner_image_url,
        // theme_color: response?.data?.location?.settings?.theme_color,
        // preferred_language: response?.data?.location?.settings?.preferred_language,
        // logo: response?.data?.business?.logo_url,
        slug: response?.data?.business?.slug,
        number_of_receipt_copies: response?.data?.setting?.order_printer?.number_of_receipt_copies,
        print_scheduled_order_before:
          response?.data?.setting?.order_printer?.print_scheduled_order_before,
        new_order_tone: response?.data?.setting?.order_printer?.new_order_tone,
        printer_name: response?.data?.setting?.order_printer?.printer_name,
        notify_allergy_warning: response?.data?.setting?.allergy_warning?.notify_allergy_warning,
        allergy_warning_message: response?.data?.setting?.allergy_warning?.allergy_warning_message,
        email_alert: response?.data?.setting?.order_notification?.email_alert,
        dinein_email_alert: response?.data?.setting?.order_notification?.dinein_email_alert,
        sms_alert: response?.data?.setting?.order_notification?.sms_alert,
        print_order_request_time:
          response?.data?.setting?.order_notification?.print_order_request_time,
        call_alert: response?.data?.setting?.order_notification?.call_alert,
        notify_not_confirmed: response?.data?.setting?.order_notification?.notify_not_confirmed,
        email: response?.data?.setting?.order_notification?.email,
        phone_number: response?.data?.setting?.order_notification?.phone_number,
        online_ordering: response?.data?.setting?.online_ordering,
        call_alert_not_confirmed:
          response?.data?.setting?.order_notification?.call_alert_not_confirmed,
        theme_color: response?.data?.setting?.theme_appearance?.theme_color,
        banner_image_url: response?.data?.setting?.theme_appearance?.banner_image_url,
        order_url: response?.data?.setting?.order_url,
        is_delivery_zone_available: response?.data?.is_delivery_zone_available,
        auto_confirm: response?.data?.setting?.order_option?.auto_confirm,
        print_clover_order: response?.data?.setting?.order_option?.print_clover_order,
        print_scheduled_order: response?.data?.setting?.order_option?.print_scheduled_order,
        item_availability: response?.data?.setting?.order_option?.item_availability,
        seperate_deal_section: response?.data?.setting?.seperate_deal_section,
        is_dine_in_available: response?.data?.setting?.dine_in?.is_dine_in_available,
        dine_in_accept_tip: response?.data?.setting?.dine_in?.dine_in_accept_tip,
        default_dine_in_tip: response?.data?.setting?.dine_in?.default_dine_in_tip,
        dine_in_customer_notes: response?.data?.setting?.dine_in?.dine_in_customer_notes,
        grid_view: response?.data?.setting?.grid_view,
        display_category_image: response?.data?.setting?.display_category_image,
        allow_service_fees: response?.data?.setting?.service_fees?.allow_service_fees,
        service_fee: (response?.data?.setting?.service_fees?.service_fee / 100).toFixed(2),
        special_instructions: response?.data?.setting?.special_instructions,
        theme_type: response?.data?.setting?.theme_appearance?.theme_type,
        verify_zip_code: response?.data?.setting?.verify_zip_code,
        number_sequence: response?.data?.setting?.number_sequence,
        order_special_instructions: response?.data?.setting?.order_special_instructions,
        disable_order_notes: response?.data?.setting?.order_notification?.disable_order_notes,
        display_verification_notes: response?.data?.setting?.display_verification_notes,
        show_phone_number: response?.data?.setting?.show_phone_number,
        google_analytics_code: response?.data?.setting?.analytics?.google_analytics_code,
        facebook_pixel_id: response?.data?.setting?.analytics?.facebook_pixel_id,
        order_confirmation_message: response?.data?.setting?.order_confirmation_message,
        pick_up_large_order: response?.data?.setting?.pick_up?.pick_up_large_order,
        pick_up_subtotal: (response?.data?.setting?.pick_up?.pick_up_subtotal / 100).toFixed(2),
        pick_up_extra_time: response?.data?.setting?.pick_up?.pick_up_extra_time,
        delivery_large_order: response?.data?.setting?.delivery?.delivery_large_order,
        delivery_subtotal: (response?.data?.setting?.delivery?.delivery_subtotal / 100).toFixed(2),
        delivery_extra_time: response?.data?.setting?.delivery?.delivery_extra_time,
        show_savings: response?.data?.setting?.show_savings,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_CATERING_SETTINGS() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(catering_settings)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cateringSetting: {
          is_pick_up_available: response?.data?.catering_setting?.pick_up?.is_pick_up_available,
          pick_up_count: response?.data?.catering_setting?.pick_up?.pick_up_count,
          pick_up_interval: response?.data?.catering_setting?.pick_up?.pick_up_interval,
          pick_up_minimum_time_to_order:
            response?.data?.catering_setting?.pick_up?.pick_up_minimum_time_to_order,
          pick_up_maximum_order_in_days:
            response?.data?.catering_setting?.pick_up?.pick_up_maximum_order_in_days,
          pick_up_cut_off_time_for_next_day:
            response?.data?.catering_setting?.pick_up?.pick_up_cut_off_time_for_next_day,
          pick_up_accept_tip: response?.data?.catering_setting?.pick_up?.pick_up_accept_tip,
          default_pick_up_tip: response?.data?.catering_setting?.pick_up?.default_pick_up_tip,
          is_delivery_available: response?.data?.catering_setting?.delivery?.is_delivery_available,
          delivery_count: response?.data?.catering_setting?.delivery?.delivery_count,
          delivery_interval: response?.data?.catering_setting?.delivery?.delivery_interval,
          delivery_minimum_time_to_order:
            response?.data?.catering_setting?.delivery?.delivery_minimum_time_to_order,
          delivery_maximum_order_in_days:
            response?.data?.catering_setting?.delivery?.delivery_maximum_order_in_days,
          delivery_cut_off_time_for_next_day:
            response?.data?.catering_setting?.delivery?.delivery_cut_off_time_for_next_day,
          delivery_accept_tip: response?.data?.catering_setting?.delivery?.delivery_accept_tip,
          default_delivery_tip: response?.data?.catering_setting?.delivery?.default_delivery_tip,
          verify_age_before_order:
            response?.data?.catering_setting?.age_verification?.verify_age_before_order,
          minimum_age: response?.data?.catering_setting?.age_verification?.minimum_age,
          age_verification_message:
            response?.data?.catering_setting?.age_verification?.age_verification_message,
          slug: response?.data?.business?.slug,
          notify_allergy_warning:
            response?.data?.catering_setting?.allergy_warning?.notify_allergy_warning,
          email_alert: response?.data?.catering_setting?.order_notification?.email_alert,
          reminder_email_alert:
            response?.data?.catering_setting?.order_notification?.reminder_email_alert,
          reminder_email_hours:
            response?.data?.catering_setting?.order_notification?.reminder_email_hours,
          allergy_warning_message:
            response?.data?.catering_setting?.allergy_warning?.allergy_warning_message,
          online_ordering: response?.data?.catering_setting?.online_ordering,
          theme_color: response?.data?.catering_setting?.theme_appearance?.theme_color,
          banner_image_url: response?.data?.catering_setting?.theme_appearance?.banner_image_url,
          order_url: response?.data?.catering_setting?.order_url,
          auto_confirm: response?.data?.catering_setting?.order_option?.auto_confirm,
          print_clover_order: response?.data?.catering_setting?.order_option?.print_clover_order,
          print_scheduled_order:
            response?.data?.catering_setting?.order_option?.print_scheduled_order,
          item_availability: response?.data?.catering_setting?.order_option?.item_availability,
          seperate_deal_section: response?.data?.catering_setting?.seperate_deal_section,
          grid_view: response?.data?.catering_setting?.grid_view,
          display_category_image: response?.data?.catering_setting?.display_category_image,
          allow_service_fees: response?.data?.catering_setting?.service_fees?.allow_service_fees,
          service_fee: (response?.data?.catering_setting?.service_fees?.service_fee / 100).toFixed(
            2,
          ),
          business_name_section: response?.data?.catering_setting?.business_name_section,
          theme_type: response?.data?.catering_setting?.theme_appearance?.theme_type,
          verify_zip_code: response?.data?.catering_setting?.verify_zip_code,
          number_sequence: response?.data?.catering_setting?.number_sequence,
          google_analytics_code: response?.data?.catering_setting?.analytics?.google_analytics_code,
          facebook_pixel_id: response?.data?.catering_setting?.analytics?.facebook_pixel_id,
          order_confirmation_message: response?.data?.catering_setting?.order_confirmation_message,
          special_instructions: response?.data?.catering_setting?.special_instructions,
          order_special_instructions: response?.data?.catering_setting?.order_special_instructions,
          disable_rewards: response?.data?.catering_setting?.disable_rewards,
          is_minimum_order: response?.data?.catering_setting?.pick_up?.is_minimum_order,
          minimum_order_value: (response?.data?.catering_setting?.pick_up?.minimum_order_value / 100).toFixed(2),
          show_savings: response?.data?.catering_setting?.show_savings,
        },
        is_catering_delivery_zone_available: response?.data?.is_delivery_zone_available,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_STORE_DESIGN() {
  const { response } = yield call(store_design)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        banner_image: response?.data?.business?.settings?.banner_image_url,
        theme_color: response?.data?.business?.settings?.theme_color,
        logo: response?.data?.business?.logo_url,
        slug: response?.data?.business?.slug,
      },
    })
  }
}

export function* UPDATE_ONLINE_ORDER_SETTINGS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })

  const { updatedData } = payload
  const { response } = yield call(onlineordersettingsupd, updatedData)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        display_menu_picture: response?.data?.setting?.menu_appearance?.display_menu_picture,
        display_allergy_ingredients:
          response?.data?.setting?.menu_appearance?.display_allergy_ingredients,
        display_menu_preferences:
          response?.data?.setting?.menu_appearance?.display_menu_preferences,
        display_menu_calorie_count:
          response?.data?.setting?.menu_appearance?.display_menu_calorie_count,
        is_pick_up_available: response?.data?.setting?.pick_up?.is_pick_up_available,
        pick_up_asap: response?.data?.setting?.pick_up?.pick_up_asap,
        pick_up_scheduled: response?.data?.setting?.pick_up?.pick_up_scheduled,
        pick_up_count: response?.data?.setting?.pick_up?.pick_up_count,
        pick_up_interval: response?.data?.setting?.pick_up?.pick_up_interval,
        pick_up_minimum_time_to_order:
          response?.data?.setting?.pick_up?.pick_up_minimum_time_to_order,
        pick_up_maximum_order_in_days:
          response?.data?.setting?.pick_up?.pick_up_maximum_order_in_days,
        pick_up_cut_off_time_for_next_day:
          response?.data?.setting?.pick_up?.pick_up_cut_off_time_for_next_day,
        average_pick_up_time: response?.data?.setting?.pick_up?.average_pick_up_time,
        pick_up_accept_tip: response?.data?.setting?.pick_up?.pick_up_accept_tip,
        default_pick_up_tip: response?.data?.setting?.pick_up?.default_pick_up_tip,
        is_delivery_available: response?.data?.setting?.delivery?.is_delivery_available,
        delivery_asap: response?.data?.setting?.delivery?.delivery_asap,
        delivery_scheduled: response?.data?.setting?.delivery?.delivery_scheduled,
        delivery_count: response?.data?.setting?.delivery?.delivery_count,
        delivery_interval: response?.data?.setting?.delivery?.delivery_interval,
        delivery_minimum_time_to_order:
          response?.data?.setting?.delivery?.delivery_minimum_time_to_order,
        delivery_maximum_order_in_days:
          response?.data?.setting?.delivery?.delivery_maximum_order_in_days,
        delivery_cut_off_time_for_next_day:
          response?.data?.setting?.delivery?.delivery_cut_off_time_for_next_day,
        average_delivery_time: response?.data?.setting?.delivery?.average_delivery_time,
        delivery_accept_tip: response?.data?.setting?.delivery?.delivery_accept_tip,
        default_delivery_tip: response?.data?.setting?.delivery?.default_delivery_tip,
        pick_up_card_online: response?.data?.setting?.payments?.pick_up?.card_online,
        pick_up_card_pay_at_counter:
          response?.data?.setting?.payments?.pick_up?.card_pay_at_counter,
        pick_up_cash: response?.data?.setting?.payments?.pick_up?.cash,
        delivery_card_online: response?.data?.setting?.payments?.delivery?.card_online,
        delivery_card_pay_on_delivery:
          response?.data?.setting?.payments?.delivery?.card_pay_on_delivery,
        delivery_cash: response?.data?.setting?.payments?.delivery?.cash,
        verify_age_before_order: response?.data?.setting?.age_verification?.verify_age_before_order,
        minimum_age: response?.data?.setting?.age_verification?.minimum_age,
        age_verification_message:
          response?.data?.setting?.age_verification?.age_verification_message,
        // banner_image: response?.data?.location?.settings?.banner_image_url,
        // theme_color: response?.data?.location?.settings?.theme_color,
        // preferred_language: response?.data?.location?.settings?.preferred_language,
        // logo: response?.data?.business?.logo_url,
        number_of_receipt_copies: response?.data?.setting?.order_printer?.number_of_receipt_copies,
        print_scheduled_order_before:
          response?.data?.setting?.order_printer?.print_scheduled_order_before,
        new_order_tone: response?.data?.setting?.order_printer?.new_order_tone,
        slug: response?.data?.business?.slug,
        printer_name: response?.data?.setting?.order_printer?.printer_name,
        notify_allergy_warning: response?.data?.setting?.allergy_warning?.notify_allergy_warning,
        allergy_warning_message: response?.data?.setting?.allergy_warning?.allergy_warning_message,
        email_alert: response?.data?.setting?.order_notification?.email_alert,
        dinein_email_alert: response?.data?.setting?.order_notification?.dinein_email_alert,
        sms_alert: response?.data?.setting?.order_notification?.sms_alert,
        print_order_request_time:
          response?.data?.setting?.order_notification?.print_order_request_time,
        call_alert: response?.data?.setting?.order_notification?.call_alert,
        notify_not_confirmed: response?.data?.setting?.order_notification?.notify_not_confirmed,
        call_alert_not_confirmed:
          response?.data?.setting?.order_notification?.call_alert_not_confirmed,
        email: response?.data?.setting?.order_notification?.email,
        phone_number: response?.data?.setting?.order_notification?.phone_number,
        online_ordering: response?.data?.setting?.online_ordering,
        order_url: response?.data?.setting?.order_url,
        theme_color: response?.data?.setting?.theme_appearance?.theme_color,
        auto_confirm: response?.data?.setting?.order_option?.auto_confirm,
        print_clover_order: response?.data?.setting?.order_option?.print_clover_order,
        print_scheduled_order: response?.data?.setting?.order_option?.print_scheduled_order,
        item_availability: response?.data?.setting?.order_option?.item_availability,
        seperate_deal_section: response?.data?.setting?.seperate_deal_section,
        is_dine_in_available: response?.data?.setting?.dine_in?.is_dine_in_available,
        dine_in_accept_tip: response?.data?.setting?.dine_in?.dine_in_accept_tip,
        default_dine_in_tip: response?.data?.setting?.dine_in?.default_dine_in_tip,
        dine_in_customer_notes: response?.data?.setting?.dine_in?.dine_in_customer_notes,
        grid_view: response?.data?.setting?.grid_view,
        display_category_image: response?.data?.setting?.display_category_image,
        allow_service_fees: response?.data?.setting?.service_fees?.allow_service_fees,
        service_fee: (response?.data?.setting?.service_fees?.service_fee / 100).toFixed(2),
        special_instructions: response?.data?.setting?.special_instructions,
        theme_type: response?.data?.setting?.theme_appearance?.theme_type,
        verify_zip_code: response?.data?.setting?.verify_zip_code,
        number_sequence: response?.data?.setting?.number_sequence,
        order_special_instructions: response?.data?.setting?.order_special_instructions,
        disable_order_notes: response?.data?.setting?.order_notification?.disable_order_notes,
        display_verification_notes: response?.data?.setting?.display_verification_notes,
        show_phone_number: response?.data?.setting?.show_phone_number,
        google_analytics_code: response?.data?.setting?.analytics?.google_analytics_code,
        facebook_pixel_id: response?.data?.setting?.analytics?.facebook_pixel_id,
        order_confirmation_message: response?.data?.setting?.order_confirmation_message,
        pick_up_large_order: response?.data?.setting?.pick_up?.pick_up_large_order,
        pick_up_subtotal: (response?.data?.setting?.pick_up?.pick_up_subtotal / 100).toFixed(2),
        pick_up_extra_time: response?.data?.setting?.pick_up?.pick_up_extra_time,
        delivery_large_order: response?.data?.setting?.delivery?.delivery_large_order,
        delivery_subtotal: (response?.data?.setting?.delivery?.delivery_subtotal / 100).toFixed(2),
        delivery_extra_time: response?.data?.setting?.delivery?.delivery_extra_time,
        show_savings: response?.data?.setting?.show_savings,
        // is_delivery_zone_available: response?.data?.is_delivery_zone_available
      },
    })
    yield put({
      type: userActions.UPDATE_ONBOARD_STATUS,
      payload: onboardStatusKeys.cardDesign,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* UPDATE_CATERING_SETTINGS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })

  const { updatedData } = payload
  const { response } = yield call(cateringsettingsupd, updatedData)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        cateringSetting: {
          is_pick_up_available: response?.data?.catering_setting?.pick_up?.is_pick_up_available,
          pick_up_count: response?.data?.catering_setting?.pick_up?.pick_up_count,
          pick_up_interval: response?.data?.catering_setting?.pick_up?.pick_up_interval,
          pick_up_minimum_time_to_order:
            response?.data?.catering_setting?.pick_up?.pick_up_minimum_time_to_order,
          pick_up_maximum_order_in_days:
            response?.data?.catering_setting?.pick_up?.pick_up_maximum_order_in_days,
          pick_up_cut_off_time_for_next_day:
            response?.data?.catering_setting?.pick_up?.pick_up_cut_off_time_for_next_day,
          pick_up_accept_tip: response?.data?.catering_setting?.pick_up?.pick_up_accept_tip,
          default_pick_up_tip: response?.data?.catering_setting?.pick_up?.default_pick_up_tip,
          is_delivery_available: response?.data?.catering_setting?.delivery?.is_delivery_available,
          delivery_count: response?.data?.catering_setting?.delivery?.delivery_count,
          delivery_interval: response?.data?.catering_setting?.delivery?.delivery_interval,
          delivery_minimum_time_to_order:
            response?.data?.catering_setting?.delivery?.delivery_minimum_time_to_order,
          delivery_maximum_order_in_days:
            response?.data?.catering_setting?.delivery?.delivery_maximum_order_in_days,
          delivery_cut_off_time_for_next_day:
            response?.data?.catering_setting?.delivery?.delivery_cut_off_time_for_next_day,
          delivery_accept_tip: response?.data?.catering_setting?.delivery?.delivery_accept_tip,
          default_delivery_tip: response?.data?.catering_setting?.delivery?.default_delivery_tip,
          verify_age_before_order:
            response?.data?.catering_setting?.age_verification?.verify_age_before_order,
          minimum_age: response?.data?.catering_setting?.age_verification?.minimum_age,
          age_verification_message:
            response?.data?.catering_setting?.age_verification?.age_verification_message,
          slug: response?.data?.business?.slug,
          notify_allergy_warning:
            response?.data?.catering_setting?.allergy_warning?.notify_allergy_warning,
          allergy_warning_message:
            response?.data?.catering_setting?.allergy_warning?.allergy_warning_message,
          email_alert: response?.data?.catering_setting?.order_notification?.email_alert,
          reminder_email_alert:
            response?.data?.catering_setting?.order_notification?.reminder_email_alert,
          reminder_email_hours:
            response?.data?.catering_setting?.order_notification?.reminder_email_hours,
          online_ordering: response?.data?.catering_setting?.online_ordering,
          theme_color: response?.data?.catering_setting?.theme_appearance?.theme_color,
          banner_image_url: response?.data?.catering_setting?.theme_appearance?.banner_image_url,
          order_url: response?.data?.catering_setting?.order_url,
          auto_confirm: response?.data?.catering_setting?.order_option?.auto_confirm,
          print_clover_order: response?.data?.catering_setting?.order_option?.print_clover_order,
          print_scheduled_order:
            response?.data?.catering_setting?.order_option?.print_scheduled_order,
          item_availability: response?.data?.catering_setting?.order_option?.item_availability,
          seperate_deal_section: response?.data?.catering_setting?.seperate_deal_section,
          grid_view: response?.data?.catering_setting?.grid_view,
          display_category_image: response?.data?.catering_setting?.display_category_image,
          allow_service_fees: response?.data?.catering_setting?.service_fees?.allow_service_fees,
          service_fee: (response?.data?.catering_setting?.service_fees?.service_fee / 100).toFixed(
            2,
          ),
          business_name_section: response?.data?.catering_setting?.business_name_section,
          theme_type: response?.data?.catering_setting?.theme_appearance?.theme_type,
          verify_zip_code: response?.data?.catering_setting?.verify_zip_code,
          number_sequence: response?.data?.catering_setting?.number_sequence,
          google_analytics_code: response?.data?.catering_setting?.analytics?.google_analytics_code,
          facebook_pixel_id: response?.data?.catering_setting?.analytics?.facebook_pixel_id,
          order_confirmation_message: response?.data?.catering_setting?.order_confirmation_message,
          special_instructions: response?.data?.catering_setting?.special_instructions,
          order_special_instructions: response?.data?.catering_setting?.order_special_instructions,
          disable_rewards: response?.data?.catering_setting?.disable_rewards,
          is_minimum_order: response?.data?.catering_setting?.pick_up?.is_minimum_order,
          minimum_order_value: (response?.data?.catering_setting?.pick_up?.minimum_order_value / 100).toFixed(2),
          show_savings: response?.data?.catering_setting?.show_savings,
        },
      },
    })
    yield put({
      type: userActions.UPDATE_ONBOARD_STATUS,
      payload: onboardStatusKeys.cardDesign,
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* UPDATE_STORE_DESIGN({ payload }) {
  const { updatedData } = payload
  const { response } = yield call(storedesignupd, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      banner_image: response?.data?.business?.settings?.banner_image_url,
      theme_color: response?.data?.business?.settings?.theme_color,
      logo: response?.data?.business?.logo_url,
      slug: response?.data?.business?.slug,
    },
  })
}

export function* ADD_OPENING_HOUR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: 'opening',
    },
  })
  const { response } = yield call(addOpeningHour, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: userActions.UPDATE_ONBOARD_STATUS,
      payload: onboardStatusKeys.businessProfile,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        openingHours: response?.data?.location?.location_hours,
      },
    })
  }
}

export function* EDIT_OPENING_HOUR({ payload }) {
  const { type, hour_id, hours } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: hour_id,
    },
  })
  const { response } = yield call(editOpeningHour, hour_id, { type, hours })
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        openingHours: response?.data?.location?.location_hours,
      },
    })
  }
}

export function* DELETE_OPENING_HOUR({ payload }) {
  const { hour_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: hour_id,
    },
  })
  const { response } = yield call(removeOpeningHour, hour_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        removeHour: null,
        openingHours: response?.data?.location?.location_hours,
      },
    })
  }
}

export function* ADD_PICKUP_HOUR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: 'pickup',
    },
  })
  const { response } = yield call(addPickupHour, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        pickupHourInfo: response?.data?.location?.pickup,
      },
    })
  }
}

export function* EDIT_PICKUP_HOUR({ payload }) {
  const { type, hour_id, hours } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: hour_id,
    },
  })
  const { response } = yield call(editPickupHour, hour_id, { type, hours })
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        pickupHourInfo: response?.data?.location?.pickup,
      },
    })
  }
}

export function* DELETE_PICKUP_HOUR({ payload }) {
  const { hour_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: hour_id,
    },
  })
  const { response } = yield call(removePickupHour, hour_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        removeHour: null,
        pickupHourInfo: response?.data?.location?.pickup,
      },
    })
  }
}

export function* DELETE_ALL_PICKUP_HOUR() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showPickupRemove: true,
    },
  })
  const { response } = yield call(deleteallpickuphr)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showPickupRemove: false,
        pickupHourInfo: response?.data?.location?.pickup,
      },
    })
  }
}

export function* DELETE_ALL_DELIVERY_HOUR() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showDeliveryRemove: true,
    },
  })
  const { response } = yield call(deletealldeliveryhr)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        showDeliveryRemove: false,
        deliveryHourInfo: response?.data?.location?.delivery,
      },
    })
  }
}

export function* ADD_DELIVERY_HOUR({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: 'delivery',
    },
  })
  const { response } = yield call(addDeliveryHour, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        deliveryHourInfo: response?.data?.location?.delivery,
      },
    })
  }
}

export function* EDIT_DELIVERY_HOUR({ payload }) {
  const { type, hour_id, hours } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: hour_id,
    },
  })
  const { response } = yield call(editDeliveryHour, hour_id, { type, hours })
  yield put({
    type: actions.SET_STATE,
    payload: {
      savingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        selectedHour: null,
        deliveryHourInfo: response?.data?.location?.delivery,
      },
    })
  }
}

export function* DELETE_DELIVERY_HOUR({ payload }) {
  const { hour_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: hour_id,
    },
  })
  const { response } = yield call(removeDeliveryHour, hour_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removingHour: null,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        removeHour: null,
        deliveryHourInfo: response?.data?.location?.delivery,
      },
    })
  }
}

export function* SAVE_WEBSITE_MANAGER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      websiteSetupLoading: true,
    },
  })
  const { updatedData } = payload
  const { response } = yield call(savewebsitemanager, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      websiteSetupLoading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
        website_theme_color_info: response?.data?.theme_color,
      },
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        websiteSetupLoading: false,
      },
    })
  }
}

export function* SAVE_SEO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      SocialMediaLoading: true,
    },
  })
  const { response } = yield call(saveseo, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      SocialMediaLoading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        // SocialMediaLoading: false,
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* SAVE_GALLERY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
      gallerybtndisabled: true,
      gallerybtnloading: true,
    },
  })
  const { response } = yield call(savegallery, payload)
  yield put({
    type: actions.SET_STATE,
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        uploading: false,
        gallerybtndisabled: false,
        gallerybtnloading: false,
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* SAVE_POPUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      popUpImageAdding: true,
    },
  })
  const { response } = yield call(savepopup, payload)

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
        popUpImageAdding: false,
      },
    })
  }
}

export function* UPDATE_PROMOTION_URL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      popUpurlAdding: true,
    },
  })
  const { response } = yield call(savepopupurl, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
        popUpurlAdding: false,
        displayPromotionUrlModal: false,
      },
    })
  }
}

export function* GET_WEBSITE_MANAGER() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(getwebsitemanager)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
        website_theme_color_info: response?.data?.theme_color,
      },
    })
  }
}

export function* DELETE_GALLERY_IMAGE({ payload }) {
  const { gallery_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(delete_gallery_image, gallery_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* ACTIVE_WEBSITE_POPUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(activewebsitepopup, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* INACTIVE_WEBSITE_POPUP({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(inactivewebsitepopup, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* DELETE_POPUP_IMAGE({ payload }) {
  const { popup_id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removepopup, popup_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* ADD_STORE_HOURS({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(addStoreHours, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        openingHours: response?.data?.location?.location_hours,
        pickupHourInfo: response?.data?.location?.pickup,
        deliveryHourInfo: response?.data?.location?.delivery,
      },
    })
  }
}

export function* ADD_BLOCKED_DATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
      loading: true,
    },
  })
  const { response, error } = yield call(addHoliday, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_CREATE,
      payload: {
        holidays: response?.data?.location?.holiday_hours,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        create: errors,
      },
    })
  }
}

export function* EDIT_BLOCKED_DATE({ payload }) {
  const { _id, holidays } = payload
  console.log('holidays', holidays)
  yield put({
    type: actions.SET_STATE,
    payload: {
      update: true,
      loading: true,
    },
  })
  const { response, error } = yield call(editHoliday, _id, holidays)
  yield put({
    type: actions.SET_STATE,
    payload: {
      update: false,
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.EDIT_DATA,
      payload: {
        holidays: response?.data?.location?.holiday_hours,
      },
    })
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        update: errors,
      },
    })
  }
}

export function* DELETE_BLOCKED_DATE({ payload }) {
  const { _id } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(removeHoliday, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.REMOVE_DATA,
      payload: {
        holidays: response?.data?.location?.holiday_hours,
      },
    })
  }
}

export function* SAVE_MENU_IMAGE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
      gallerybtndisabled: true,
      gallerybtnloading: true,
    },
  })
  const { response } = yield call(savemenu, payload)
  yield put({
    type: actions.SET_STATE,
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        uploading: false,
        gallerybtndisabled: false,
        gallerybtnloading: false,
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export function* DELETE_MENU_IMAGE({ payload }) {
  const { gallery_id, page } = payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(delete_menu_image, gallery_id, page)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        website_manager: response?.data?.website_manager,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_STORE, GET_STORE),
    takeLatest(actions.GET_ORDER_SETTINGS, GET_ORDER_SETTINGS),
    takeLatest(actions.GET_CATERING_SETTINGS, GET_CATERING_SETTINGS),
    takeLatest(actions.GET_STORE_DESIGN, GET_STORE_DESIGN),
    takeLatest(actions.UPDATE_ONLINE_ORDER_SETTINGS, UPDATE_ONLINE_ORDER_SETTINGS),
    takeLatest(actions.UPDATE_CATERING_SETTINGS, UPDATE_CATERING_SETTINGS),
    takeLatest(actions.UPDATE_STORE_DESIGN, UPDATE_STORE_DESIGN),
    takeLatest(actions.UPDATE_STORE, UPDATE_STORE),
    takeLatest(actions.ADD_STORE_HOURS, ADD_STORE_HOURS),
    takeLatest(actions.ADD_OPENING_HOUR, ADD_OPENING_HOUR),
    takeLatest(actions.EDIT_OPENING_HOUR, EDIT_OPENING_HOUR),
    takeLatest(actions.DELETE_OPENING_HOUR, DELETE_OPENING_HOUR),
    takeLatest(actions.ADD_PICKUP_HOUR, ADD_PICKUP_HOUR),
    takeLatest(actions.EDIT_PICKUP_HOUR, EDIT_PICKUP_HOUR),
    takeLatest(actions.DELETE_PICKUP_HOUR, DELETE_PICKUP_HOUR),
    takeLatest(actions.DELETE_ALL_PICKUP_HOUR, DELETE_ALL_PICKUP_HOUR),
    takeLatest(actions.ADD_DELIVERY_HOUR, ADD_DELIVERY_HOUR),
    takeLatest(actions.EDIT_DELIVERY_HOUR, EDIT_DELIVERY_HOUR),
    takeLatest(actions.DELETE_DELIVERY_HOUR, DELETE_DELIVERY_HOUR),
    takeLatest(actions.DELETE_ALL_DELIVERY_HOUR, DELETE_ALL_DELIVERY_HOUR),
    takeLatest(actions.SAVE_WEBSITE_MANAGER, SAVE_WEBSITE_MANAGER),
    takeLatest(actions.SAVE_GALLERY, SAVE_GALLERY),
    takeLatest(actions.SAVE_SEO, SAVE_SEO),
    takeLatest(actions.GET_WEBSITE_MANAGER, GET_WEBSITE_MANAGER),
    takeLatest(actions.DELETE_POPUP_IMAGE, DELETE_POPUP_IMAGE),
    takeLatest(actions.SAVE_POPUP, SAVE_POPUP),
    takeLatest(actions.UPDATE_PROMOTION_URL, UPDATE_PROMOTION_URL),
    takeLatest(actions.ACTIVE_WEBSITE_POPUP, ACTIVE_WEBSITE_POPUP),
    takeLatest(actions.INACTIVE_WEBSITE_POPUP, INACTIVE_WEBSITE_POPUP),
    takeLatest(actions.DELETE_GALLERY_IMAGE, DELETE_GALLERY_IMAGE),
    takeLatest(actions.ADD_BLOCKED_DATE, ADD_BLOCKED_DATE),
    takeLatest(actions.EDIT_BLOCKED_DATE, EDIT_BLOCKED_DATE),
    takeLatest(actions.DELETE_BLOCKED_DATE, DELETE_BLOCKED_DATE),
    takeLatest(actions.SAVE_MENU_IMAGE, SAVE_MENU_IMAGE),
    takeLatest(actions.DELETE_MENU_IMAGE, DELETE_MENU_IMAGE),
  ])
}
