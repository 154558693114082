import apiClient from 'services/axios'

const BASE_URL = 'staff'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(couponId, payload) {
  return apiClient
    .put(`${BASE_URL}/${couponId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(couponId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${couponId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
