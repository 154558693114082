import apiClient from 'services/axios'
import apiFormDataClient from 'services/axios-formdata'

const BASE_URL = 'category'

export async function createCategory({ payload }) {
  return apiFormDataClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getCategories() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => {
      response?.data?.categories?.forEach((e) => {
        e.items.forEach((ee) => {
          ee.price = (ee.price * 0.01).toFixed(2)
          if (ee?.min_purchase_value) {
            ee.min_purchase_value = (ee.min_purchase_value * 0.01).toFixed(2)
          }
        })
      })

      return {
        response,
      }
    })
    .catch((error) => ({ error }))
}

export async function updateCategory({ payload }) {
  return apiFormDataClient
    .put(`${BASE_URL}/${payload.id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteCategory(_id) {
  return apiClient
    .delete(`${BASE_URL}/${_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updateSorting({ payload }) {
  return apiClient
    .post(`${BASE_URL}/sort`, {
      categories: payload.categories.map((e) => e._id),
    })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cloneCategory({ payload }) {
  const { id } = payload
  console.log('payload', payload)
  delete payload.id
  return apiClient
    .post(`${BASE_URL}/${id}/clone`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default {
  createCategory,
  getCategories,
  updateSorting,
  cloneCategory,
  deleteCategory,
}
