import { createSelector } from 'reselect'
import { selectCountryData } from '../country/selectors'

export const selectUser = (state) => state.user

export const selectUserData = createSelector([selectUser], (user) => {
  if (user.search && user.data) {
    return user.data.filter(
      (o) => o.name.includes(user.search) || o.user?.email?.includes(user.search),
    )
  }
  return user.data
})

export const selectStaff = createSelector([selectUser], (user) => user.staff)

export const selectBusiness = createSelector([selectUser], (user) => user.business)

export const selectLocation = createSelector([selectUser], (user) => user.location)

export const selectCurrency = createSelector(
  [selectLocation],
  (location) => location?.address?.country_details?.currency || 'USD',
)

export const selectBusinessCountry = createSelector(
  [selectBusiness, selectCountryData],
  (business, country) => {
    if (business && country) {
      const location = business.locations?.find((o) => o.is_primary)
      if (location) return country.find((o) => o.short_name === location.address.country)
    }
    return null
  },
)

export const selectAvailableLocations = createSelector(
  [selectUser, selectStaff, selectBusiness],
  (user, staff, business) => {
    if (!staff || !business) return []
    if (user.role === 'Business Owner') return business.locations
    return staff.locations?.map((location) => {
      const businessLocation = business.locations.find((o) => o._id === location.location_id)
      return businessLocation || location
    })
  },
)

export const selectCanManageCustomer = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanManageUser = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanManageLocation = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner'
})

export const selectCanManageCoupon = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanRunReport = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanAccessSetup = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanAccessStoreDesign = createSelector(selectUser, (user) => {
  if (!user) return false
  return user.role === 'Business Owner' || user.role === 'Manager'
})

export const selectCanAccessCatering = createSelector(selectLocation, (location) => {
  if (!location) return false
  return location?.catering?.status || false
})
