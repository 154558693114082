const actions = {
  SET_STATE: 'user/SET_STATE',
  SET_ERROR_STATE: 'user/SET_ERROR_STATE',
  RESET_STATE: 'user/RESET_STATE',
  REGISTER: 'user/REGISTER',
  CHANGE_EMAIL: 'user/CHANGE_EMAIL',
  RESEND_EMAIL: 'user/RESEND_EMAIL',
  CONFIRM_EMAIL: 'user/CONFIRM_EMAIL',
  LOGIN: 'user/LOGIN',
  RECOVER_PASSWORD: 'user/RECOVER_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOGOUT: 'user/LOGOUT',
  CLEAR_USER: 'user/CLEAR_USER',

  REGISTER_BUSINESS: 'user/REGISTER_BUSINESS',

  HANDLE_USER_TOKEN: 'user/HANDLE_USER_TOKEN',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  SET_LOCATION: 'user/SET_LOCATION',
  UPDATE_ONBOARD_STATUS: 'user/update/onboardstatus',
}

export default actions
