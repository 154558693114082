import apiClient from 'services/axios'
import apiFormDataClient from 'services/axios-formdata'

const BASE_URL = 'order'

export async function getOrders() {
  return (
    apiClient
      // .get(`${BASE_URL}/${payload.business_id}/${payload.location_id}`)
      .get(`${BASE_URL}`)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
  )
}

export async function updateOrder(id, payload) {
  return apiFormDataClient
    .put(`${BASE_URL}/${id}/status`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getOrderDetails(payload) {
  return apiFormDataClient
    .get(`${BASE_URL}/${payload.id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default {
  updateOrder,
  getOrders,
}
