const onboardStatusKeys = {
  businessProfile: 'store_setup',
  rewardProgram: 'menu_manager',
  cardDesign: 'online_ordering_setup',
  promotion: 'payment_setup',
  websiteWidget: 'website_widget_setup',
  feedbackQuestion: 'restaurant_app_setup',
  activateCloverApp: 'place_a_test_order',
}

export const steps = [
  onboardStatusKeys.businessProfile,
  onboardStatusKeys.rewardProgram,
  onboardStatusKeys.cardDesign,
  onboardStatusKeys.promotion,
  onboardStatusKeys.websiteWidget,
  onboardStatusKeys.feedbackQuestion,
  onboardStatusKeys.activateCloverApp,
]

export const getFirstUnfinished = (status = {}) => steps.find((step) => !status?.[step])

export const getOnboardCount = (status) => {
  const completed = steps.reduce((acc, cur) => (status[cur] ? acc + 1 : acc), 0)
  return { total: steps.length, completed }
}

export const stepDetails = {
  [onboardStatusKeys.businessProfile]: {
    stepTitle: 'Store Hours',
    title: 'Setup your store hours',
    desc: 'Setup your store opening hours and delivery hours.',
    link: {
      title: 'Update store hours',
      to: '/setup/store?tab=store',
    },
  },
  [onboardStatusKeys.rewardProgram]: {
    stepTitle: 'Menu Manager',
    title: 'Setup your menu manager',
    desc: 'Create your menu items or import from a excel spreadsheet.',
    link: {
      title: 'Create your menu',
      to: '/manage/menu',
    },
  },
  [onboardStatusKeys.cardDesign]: {
    stepTitle: 'Online Store Setup',
    title: 'Design your online store',
    desc: 'Customize and setup your online ordering store.',
    link: {
      title: 'Setup online store',
      to: '/settings/online-order',
    },
  },
  [onboardStatusKeys.promotion]: {
    stepTitle: 'Payment Setup',
    title: 'Setup your payment',
    desc: 'Accept payments online using one of our integrated payment partners',
    link: {
      title: 'Setup Payment',
      to: '/settings/integrations',
    },
  },
  [onboardStatusKeys.websiteWidget]: {
    stepTitle: 'Website Widget',
    title: 'Setup website widget',
    desc:
      'Add online ordering widget or link your store URL to your existing website or Facebook page',
    link: {
      title: 'Setup Website Widget',
      to: '/settings/website-widget',
    },
  },
  [onboardStatusKeys.feedbackQuestion]: {
    stepTitle: 'Restaurant App Setup',
    title: 'Install your restaurant app',
    desc: 'Install the restaurant app and start receiving orders on any device.',
    link: {
      title: 'Install restaurant app',
      to: '/settings/integrations',
    },
  },
  [onboardStatusKeys.activateCloverApp]: {
    stepTitle: 'Place a test order',
    title: 'Place a test order',
    desc:
      'Place your first test order and confirm the order from the order manager app to complete this step.',
    button: {
      title: 'Place order',
    },
  },
}

export default onboardStatusKeys
