const actions = {
  SET_STATE: 'reservation-setup/SET_STATE',
  GET_RESERVATION_SETUP: 'reservation-setup/GET_RESERVATION_SETUP',
  UPDATE_RESERVATION_SETUP: 'reservation-setup/UPDATE_RESERVATION_SETUP',

  ADD_RESERVATION_HOUR: 'reservation-setup/ADD_RESERVATION_HOUR',
  EDIT_RESERVATION_HOUR: 'reservation-setup/EDIT_RESERVATION_HOUR',
  DELETE_RESERVATION_HOUR: 'reservation-setup/DELETE_RESERVATION_HOUR',

  SET_ERROR_STATE: 'reservation-setup/SET_ERROR_STATE',
  REMOVE_DATA: 'reservation-setup/REMOVE_DATA',
  SHOW_CREATE: 'reservation-setup/SHOW_CREATE',
  HIDE_CREATE: 'reservation-setup/HIDE_CREATE',
  SHOW_UPDATE: 'reservation-setup/SHOW_UPDATE',
  HIDE_UPDATE: 'reservation-setup/HIDE_UPDATE',
  SHOW_DELETE: 'reservation-setup/SHOW_DELETE',
  HIDE_DELETE: 'reservation-setup/HIDE_DELETE',
  EDIT_DATA: 'reservation-setup/EDIT_DATA',

  ADD_BLOCKED_DATE: 'reservation-setup/ADD_BLOCKED_DATE',
  EDIT_BLOCKED_DATE: 'reservation-setup/EDIT_BLOCKED_DATE',
  DELETE_BLOCKED_DATE: 'reservation-setup/DELETE_BLOCKED_DATE',
}

export default actions
