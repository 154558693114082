import React, { lazy, Suspense, useEffect, useMemo } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'
import nProgress from 'nprogress'

import Layout from 'layouts'
import { getFirstUnfinished } from 'pages/admin/getting-started/status'

// import Landing from 'pages'

const FallBack = () => {
  useEffect(() => {
    nProgress.start()
    return () => {
      nProgress.done()
    }
  }, [])

  return null
}

const routes = [
  // Auth Pages
  {
    path: '/register',
    Component: lazy(() => import('pages/register')),
    exact: true,
  },
  {
    path: '/clover/register',
    Component: lazy(() => import('pages/clover/register')),
    exact: true,
  },
  {
    path: '/signup',
    Component: lazy(() => import('pages/register')),
    exact: true,
  },
  {
    path: '/confirm-email',
    Component: lazy(() => import('pages/auth/confirm-email')),
    exact: true,
  },
  {
    path: '/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/recover-password',
    Component: lazy(() => import('pages/auth/recover-password')),
    exact: true,
  },
  {
    path: '/reset-password',
    Component: lazy(() => import('pages/auth/reset-password')),
    exact: true,
  },
  {
    path: '/change-password',
    Component: lazy(() => import('pages/change-password')),
    exact: true,
  },
  {
    path: '/confirm-business',
    Component: lazy(() => import('pages/auth/confirm-business')),
    exact: true,
  },
  {
    path: '/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
  // Dashboard
  {
    path: '/dashboard',
    Component: lazy(() => import('pages/admin/dashboard')),
    exact: true,
  },
  {
    path: '/getting-started',
    Component: lazy(() => import('pages/admin/getting-started')),
    exact: true,
  },
  // Business Pages
  {
    path: '/business',
    Component: lazy(() => import('pages/business')),
    exact: true,
  },
  // User Pages
  {
    path: '/reports/sales_summary',
    Component: lazy(() => import('pages/admin/reports/sales_summary')),
    exact: true,
  },
  {
    path: '/reports/timesheet',
    Component: lazy(() => import('pages/admin/reports/timesheet')),
    exact: true,
  },
  {
    path: '/reports/delivery',
    Component: lazy(() => import('pages/admin/reports/delivery')),
    exact: true,
  },
  {
    path: '/reports/doordash',
    Component: lazy(() => import('pages/admin/reports/doordash')),
    exact: true,
  },
  {
    path: '/manage/menu',
    Component: lazy(() => import('pages/admin/manage/menu')),
    exact: true,
  },
  {
    path: '/manage/orders',
    Component: lazy(() => import('pages/admin/manage/orders')),
    exact: true,
  },
  {
    path: '/manage/customers',
    Component: lazy(() => import('pages/admin/manage/customer')),
    exact: true,
  },
  {
    path: '/manage/user',
    Component: lazy(() => import('pages/admin/manage/staff')),
    exact: true,
  },
  {
    path: '/manage/location',
    Component: lazy(() => import('pages/admin/manage/location')),
    exact: true,
  },
  {
    path: '/setup/branding',
    Component: lazy(() => import('pages/admin/setup/store-design')),
    exact: true,
  },
  {
    path: '/manage/coupon',
    Component: lazy(() => import('pages/admin/manage/coupon')),
    exact: true,
  },
  {
    path: '/manage/employees',
    Component: lazy(() => import('pages/admin/manage/employees')),
    exact: true,
  },
  {
    path: '/manage/tax',
    Component: lazy(() => import('pages/admin/manage/tax')),
    exact: true,
  },
  {
    path: '/manage/delivery-zones',
    Component: lazy(() => import('pages/admin/manage/delivery-zones')),
    exact: true,
  },
  {
    path: '/manage/catering-delivery-zones',
    Component: lazy(() => import('pages/admin/manage/catering-delivery-zones')),
    exact: true,
  },
  {
    path: '/manage/timesheets',
    Component: lazy(() => import('pages/admin/manage/timesheets')),
    exact: true,
  },
  {
    path: '/summary/sale',
    Component: lazy(() => import('pages/admin/summary/sale')),
    exact: true,
  },
  {
    path: '/setup/store',
    Component: lazy(() => import('pages/admin/setup/store')),
    exact: true,
  },
  {
    path: '/settings/online-order',
    Component: lazy(() => import('pages/admin/settings/online-order')),
    exact: true,
  },
  {
    path: '/settings/catering',
    Component: lazy(() => import('pages/admin/settings/catering')),
    exact: true,
  },
  {
    path: '/settings/online-order/old',
    Component: lazy(() => import('pages/admin/settings/online-order/index_old')),
    exact: true,
  },
  {
    path: '/settings/billing',
    Component: lazy(() => import('pages/admin/billing')),
    exact: true,
  },
  {
    path: '/settings/website-widget',
    Component: lazy(() => import('pages/admin/website-widget')),
    exact: true,
  },
  {
    path: '/settings/integrations',
    Component: lazy(() => import('pages/admin/integrations')),
    exact: true,
  },
  {
    path: '/order-history',
    Component: lazy(() => import('pages/admin/order-history')),
    exact: true,
  },
  {
    path: '/future-orders',
    Component: lazy(() => import('pages/admin/future-orders')),
    exact: true,
  },
  {
    path: '/website-manager/website-setup',
    Component: lazy(() => import('pages/website/ThemeDesign')),
    exact: true,
  },

  {
    path: '/website-manager/gallery',
    Component: lazy(() => import('pages/website/Gallery')),
    exact: true,
  },
  {
    path: '/website-manager/social-media',
    Component: lazy(() => import('pages/website/SocialMedia')),
    exact: true,
  },
  {
    path: '/website-manager/website-popup',
    Component: lazy(() => import('pages/website/Popup')),
    exact: true,
  },
  {
    path: '/website-manager/manage-domain',
    Component: lazy(() => import('pages/website/ManageDomain')),
    exact: true,
  },
  {
    path: '/reservation/setup',
    Component: lazy(() => import('pages/admin/reservation/setup')),
    exact: true,
  },
  {
    path: '/reservation/floor-plan',
    Component: lazy(() => import('pages/admin/reservation/floor-plan')),
    exact: true,
  },
  {
    path: '/manage/locations',
    Component: lazy(() => import('pages/admin/manage/locations')),
    exact: true,
  },
  {
    path: '/settings/pos',
    Component: lazy(() => import('pages/admin/settings/pos')),
    exact: true,
  },
]

const mapStateToProps = ({ settings, user }) => ({
  routerAnimation: settings.routerAnimation,
  subscription: user?.business?.subscription,
  onboard_status: user?.onboard_status,
})

const Router = ({ history, routerAnimation, subscription, onboard_status }) => {
  const isFirstUnfinished = useMemo(() => getFirstUnfinished(onboard_status), [onboard_status])
  let filteredRoutes = routes
  if (!subscription?.feature_availability?.website_manager) {
    filteredRoutes = routes.filter((e) => e.path.indexOf('website-manager') === -1)
  }
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={(state) => {
            const { location } = state
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => (
                        <Redirect to={isFirstUnfinished ? '/getting-started' : '/dashboard'} />
                      )}
                    />
                    {/* <Route exact path="/">
                      <Landing />
                    </Route> */}
                    {filteredRoutes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={FallBack}>
                                <Component />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
    </ConnectedRouter>
  )
}

export default connect(mapStateToProps)(Router)
