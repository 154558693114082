import apiClient from 'services/axios'

const BASE_URL = 'customer'

export async function load() {
  return apiClient
    .get(`${BASE_URL}/list`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function remove(customerId, payload) {
  return apiClient
    .delete(`${BASE_URL}/${customerId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function block(customerId, payload) {
  return apiClient
    .get(`${BASE_URL}/${customerId}/block`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
