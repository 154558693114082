import axios from 'axios'
import store from 'store'
import { notification } from 'antd'
import UserAction from 'redux/user/actions'
import { store as reduxStore } from 'index'

const apiFormDataClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.plento.app/v1/",
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest',
      'X-device-type': 'merchant-portal',
      'X-device-id': '80987387692',
      'X-app-version': '2.0.0',
    },
  },
})

apiFormDataClient.interceptors.request.use((request) => {
  request.params = request.params || {}
  const accessToken = store.get('user.accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  const business = store.get('user.business')
  if (business) {
    request.params.business = business._id
  }
  // const location = store.get('user.location')
  // if (location) {
  //   request.params.location = location.code
  // }
  return request
})

apiFormDataClient.interceptors.response.use(
  (response) => {
    if (response?.data?.message != null) {
      const { message } = response.data
      notification.success({
        message,
      })
    }
    return response
  },
  (error) => {
    if (error?.response?.status === 401) {
      reduxStore.dispatch({
        type: UserAction.CLEAR_USER,
      })
    } else if (error?.response?.data != null) {
      const { message } = error.response.data
      notification.error({
        message,
      })
    }
    throw error
  },
)

export default apiFormDataClient
