import React from 'react'
import { Button } from 'antd'
// import { useSelector } from 'react-redux'
// import { selectUser } from 'redux/user/selectors'
// import LanguageSwitcher from './LanguageSwitcher'
// import UserMenu from './UserMenu'
// import LocationSwitcher from './LocationSwitcher'
import { history } from 'index'
import style from './style.module.scss'
// import BusinessMenu from './BusinessMenu'

const TopBar = () => {
  // const user = useSelector(selectUser)

  return (
    <div className={`${style.topbar} top-bar-sec`}>
      {/* <div className="mr-4">
        <LocationSwitcher />
      </div>
      <div className="mr-auto" />
      <div className="mr-4">{user.role === 'Owner' && <BusinessMenu />}</div>
      <div className="mr-4">
        <LanguageSwitcher />
      </div>
      <UserMenu /> */}
      <div className="notification-card">
        Billing plan setup is incomplete. Choose a plan to go live. <Button onClick={() => history.push('/settings/billing')} className="select-btn" type="primary">Select Plan</Button>
      </div>
    </div>
  )
}

export default TopBar
