const actions = {
  SET_STATE: 'modifier-group/SET_STATE',
  CREATE_MODIFIER_GROUP: 'modifier-group/CREATE_MODIFIER_GROUP',
  UPDATE_MODIFIER_GROUP: 'modifier-group/UPDATE_MODIFIER_GROUP',
  GET_MODIFIER_GROUPS: 'modifier-group/GET_MODIFIER_GROUPS',
  SHOW_CREATE: 'modifier-group/SHOW_CREATE',
  HIDE_CREATE: 'modifier-group/HIDE_CREATE',
  SHOW_UPDATE: 'modifier-group/SHOW_UPDATE',
  HIDE_UPDATE: 'modifier-group/HIDE_UPDATE',
  SHOW_DELETE: 'modifier-group/SHOW_DELETE',
  HIDE_DELETE: 'modifier-group/HIDE_DELETE',
  REMOVE: 'modifier-group/REMOVE',
  CLONE_MODIFIER: 'modifier-group/CLONE_MODIFIER',
}

export default actions
