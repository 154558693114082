const actions = {
  SET_STATE: 'tax/SET_STATE',
  SET_ERROR_STATE: 'tax/SET_ERROR_STATE',
  EDIT_DATA: 'tax/EDIT_DATA',
  REMOVE_DATA: 'tax/REMOVE_DATA',
  SHOW_CREATE: 'tax/SHOW_CREATE',
  HIDE_CREATE: 'tax/HIDE_CREATE',
  SHOW_UPDATE: 'tax/SHOW_UPDATE',
  HIDE_UPDATE: 'tax/HIDE_UPDATE',
  SHOW_DELETE: 'tax/SHOW_DELETE',
  HIDE_DELETE: 'tax/HIDE_DELETE',

  LOAD_ALL: 'tax/LOAD_ALL',
  CREATE: 'tax/CREATE',
  UPDATE: 'tax/UPDATE',
  REMOVE: 'tax/REMOVE',
}

export default actions
