import actions from './actions'

const initialState = {
  loading: false,
  updating: false,
  removing: false,
  uploading: false,
  reservationSetupDetails: null,
  reservationHours: null,
  blockDates: null,
  depositSettings: null,
  selectedHour: null,
  savingHour: null,
  removeHour: null,
  removingHour: null,
  hideaddbutton: false,
  data: null,
  data1: null,
  data2: null,
  preview_url: null,
  reservation_interval: null,
  minimum_time_to_book: null,
  maximum_time_to_book: null,
  meal_duration: null,
  is_restaurant_message: null,
  restaurant_message: null,
  cancellation_policy_message: null,
  theme_color: '',
  background_image: '',
  background_image_url: null,
  addopeninghrs: null,
  editopeninghrs: null,
  actiontype: null,
  creating: false,
  endhourtime: null,
  canDisplayOpeningHourForm: false,
  isSubmittingOpeningHour: false,
  displayPromotionUrlModal: false,
  form_name: null,
  selectedData: null,
  displayAddDrawer: false,
  displayRemoveModal: false,
  reservationURL: null,
  errors: {
    create: null,
    update: null,
  },
}

export default function reservationSetupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    case actions.EDIT_DATA:
      return {
        ...state,
        ...action.payload,
        displayUpdateDrawer: false,
      }
    case actions.REMOVE_DATA:
      return {
        ...state,
        ...action.payload,
        displayRemoveModal: false,
      }
    case actions.SHOW_CREATE:
      return { ...state, displayAddDrawer: true }
    case actions.HIDE_CREATE:
      return { ...state, ...action.payload, displayAddDrawer: false }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.blockedDate, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.blockedDate, displayRemoveModal: true }
    case actions.HIDE_DELETE:
      return { ...state, displayRemoveModal: false }
    default:
      return state
  }
}
