import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import businessType from './business-type/reducers'
import country from './country/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import business from './business/reducers'
import location from './location/reducers'
import tax from './tax/reducers'
import integration from './integration/reducers'
import giftCard from './gift-card/reducers'
import customer from './customer/reducers'
import setting from './setting/reducers'
import staff from './staff/reducers'
import coupon from './coupon/reducers'
import employee from './employee/reducers'
import settings from './settings/reducers'
import RootAction from './actions'
import category from './category/reducers'
import item from './item/reducers'
import deliveryradius from './deliveryradius/reducers'
import cateringDeliveryRadius from './catering-delivery-radius/reducers'
import order from './order/reducers'
import modifierGroup from './modifier-group/reducers'
import orderhistory from './orderhistory/reducers'
import reports from './reports/reducers'
import widget from './widget/reducers'
import subscription from './subscription/reducers'
import floorplan from './floorplan/reducers'
import reservationSetup from './reservation-setup/reducers'
import locations from './locations/reducers'
import combo from './combo/reducers'

export default (history) => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    businessType,
    country,
    user,
    menu,
    business,
    location,
    tax,
    integration,
    giftCard,
    customer,
    setting,
    staff,
    coupon,
    employee,
    settings,
    category,
    item,
    deliveryradius,
    cateringDeliveryRadius,
    modifierGroup,
    orderhistory,
    reports,
    order,
    widget,
    subscription,
    floorplan,
    reservationSetup,
    locations,
    combo,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
