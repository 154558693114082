import actions from './actions'

const initialState = {
  rooms: [],
  elements: [],
  selectedRoom: null
}

export default function couponReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
