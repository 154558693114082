const actions = {
  SET_STATE: 'order/SET_STATE',
  UPDATE_ORDER: 'order/UPDATE_ORDER',
  UPDATE_STATUS: 'order/UPDATE_STATUS',
  GET_ORDERS: 'order/GET_ORDERS',
  SHOW_UPDATE: 'order/SHOW_UPDATE',
  HIDE_UPDATE: 'order/HIDE_UPDATE',
  GET_DETAILS: 'order/GET_DETAILS',
}

export default actions
