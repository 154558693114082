import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  createCategory,
  getCategories,
  updateCategory,
  deleteCategory,
  updateSorting,
  cloneCategory,
} from 'services/category'
import store from 'store'
import ItemActions from 'redux/item/actions'
import actions from './actions'

export function* CREATE_CATEGORY(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })

  const status = yield call(createCategory, payload)

  if (status && status.response && status.response.data) {
    const business = store.get('user.business')
    const getCatPayload = {
      payload: {
        business_id: business._id,
      },
    }
    const { response } = yield call(getCategories, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.categories,
        displayAddDrawer: false,
        creating: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
}

export function* UPDATE_CATEGORY(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })

  const status = yield call(updateCategory, payload)

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const getCatPayload = {
    payload: {
      business_id: business._id,
      // location_id: location._id,
    },
  }
  console.log('status-status', status)
  if (status && status.response && status.response.data) {
    const { response } = yield call(getCategories, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.categories,
        displayUpdateDrawer: false,
        updating: false,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
}

export function* GET_CATEGORIES(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true,
    },
  })

  const { response } = yield call(getCategories, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: false,
    },
  })
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      data: response.data.categories,
      originalData: JSON.parse(JSON.stringify(response.data.categories)),
      selectedData: null,
    },
  })

  yield put({
    type: ItemActions.SET_STATE,
    payload: {
      data: response.data.categories,
    },
  })
}

export function* REMOVE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })

  const status = yield call(deleteCategory, payload.payload._id)
  console.log(status)
  // yield put({
  //  type: actions.SET_STATE,
  //  payload: {
  //    removing: false,
  //  },
  // })

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const getCatPayload = {
    payload: {
      business_id: business._id,
      // location_id: location ? location._id : null,
    },
  }
  const { response } = yield call(getCategories, getCatPayload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      data: response.data.categories,
      originalData: JSON.parse(JSON.stringify(response.data.categories)),
      displayRemoveModal: false,
      selectedData: null,
      removing: false,
    },
  })

  yield put({
    type: ItemActions.SET_STATE,
    payload: {
      data: response.data.categories,
      originalData: JSON.parse(JSON.stringify(response.data.categories)),
    },
  })
}

export function* UPDATE_SORTING(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(updateSorting, payload)

  if (status.response.data.message) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: payload.payload.categories,
      },
    })
  }
}

export function* CLONE_CATEGORY(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      cloning: true,
    },
  })
  // console.log('payload clone', payload)

  const status = yield call(cloneCategory, payload)

  if (status?.response?.data?.message) {
    const { response } = yield call(getCategories)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        cloning: false,
        data: response.data.categories,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CREATE_CATEGORY, CREATE_CATEGORY),
    takeLatest(actions.UPDATE_CATEGORY, UPDATE_CATEGORY),
    takeLatest(actions.GET_CATEGORIES, GET_CATEGORIES),
    takeLatest(actions.CLONE_CATEGORY, CLONE_CATEGORY),
    takeLatest(actions.UPDATE_SORTING, UPDATE_SORTING),
    takeLatest(actions.REMOVE, REMOVE),
  ])
}
