import { all } from 'redux-saga/effects'
import businessType from './business-type/sagas'
import country from './country/sagas'
import user from './user/sagas'
import menu from './menu/sagas'
import business from './business/sagas'
import location from './location/sagas'
import tax from './tax/sagas'
import integration from './integration/sagas'
import giftCard from './gift-card/sagas'
import customer from './customer/sagas'
import setting from './setting/sagas'
import staff from './staff/sagas'
import coupon from './coupon/sagas'
import employee from './employee/sagas'
import settings from './settings/sagas'
import category from './category/sagas'
import item from './item/sagas'
import deliveryradius from './deliveryradius/sagas'
import cateringDeliveryRadius from './catering-delivery-radius/sagas'
import modifierGroup from './modifier-group/sagas'
import orderhistory from './orderhistory/sagas'
import reports from './reports/sagas'
import order from './order/sagas'
import widget from './widget/sagas'
import subscription from './subscription/sagas'
import floorplan from './floorplan/sagas'
import reservationSetup from './reservation-setup/sagas'
import locations from './locations/sagas'
import combo from './combo/sagas'

export default function* rootSaga() {
  yield all([
    businessType(),
    country(),
    user(),
    menu(),
    business(),
    location(),
    tax(),
    integration(),
    giftCard(),
    customer(),
    setting(),
    staff(),
    coupon(),
    employee(),
    settings(),
    category(),
    item(),
    deliveryradius(),
    cateringDeliveryRadius(),
    modifierGroup(),
    orderhistory(),
    reports(),
    order(),
    widget(),
    subscription(),
    floorplan(),
    reservationSetup(),
    locations(),
    combo(),
  ])
}
