import apiClient from 'services/axios'

const BASE_URL = 'gift-card'

export async function load() {
  return apiClient
    .get(`${BASE_URL}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadDigital() {
  return apiClient
    .get(`${BASE_URL}/digital`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSalesSummary(searchParams) {
  return apiClient
    .get(`${BASE_URL}/summary/sale?${searchParams}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadRedeemedSummary(searchParams) {
  return apiClient
    .get(`${BASE_URL}/summary/redeem?${searchParams}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function issue(data) {
  return apiClient
    .post(`${BASE_URL}`, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reload(reloadData) {
  return apiClient
    .post(`${BASE_URL}/reload-card`, reloadData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function resend(giftCardId, resendData) {
  return apiClient
    .post(`${BASE_URL}/${giftCardId}/resend`, resendData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadTransactions(giftCardId) {
  return apiClient
    .get(`${BASE_URL}/${giftCardId}/transaction`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function redeem(redeemData) {
  return apiClient
    .post(`${BASE_URL}/redeem`, redeemData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function importExternal(data) {
  return apiClient
    .post(`${BASE_URL}/import`, data)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
