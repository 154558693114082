import { all, call, put, takeLatest } from 'redux-saga/effects'
import { getItems } from 'services/item'
import { createCombo, updateCombo, deleteCombo, getCombos, cloneCombo } from 'services/combo'
import actions from './actions'

export function* LOAD_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true,
    },
  })
  const { response } = yield call(getCombos)
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: false,
    },
  })
  if (response) {
    const { combos } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: combos,
      },
    })
  }
}

export function* CREATE_COMBO(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })

  const status = yield call(createCombo, payload)

  if (status?.response?.data) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayAddDrawer: false,
        creating: false,
      },
    })
    yield call(LOAD_ALL)
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        creating: false,
      },
    })
  }
}

export function* UPDATE_COMBO(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })

  const status = yield call(updateCombo, payload)

  if (status?.response?.data) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayUpdateDrawer: false,
        updating: false,
      },
    })
    yield call(LOAD_ALL)
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        updating: false,
      },
    })
  }
}

export function* GET_ITEMS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const { response } = yield call(getItems, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      itemsList: response.data.items,
    },
  })
}

export function* REMOVE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })

  const status = yield call(deleteCombo, payload.payload._id)
  console.log(status)

  yield put({
    type: actions.SET_STATE,
    payload: {
      displayRemoveModal: false,
      removing: false,
    },
  })
  yield call(LOAD_ALL)
}

export function* CLONE_COMBO(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true,
    },
  })

  const status = yield call(cloneCombo, payload)

  if (status?.response.data?.message) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        listing: false,
      },
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.CREATE_COMBO, CREATE_COMBO),
    takeLatest(actions.UPDATE_COMBO, UPDATE_COMBO),
    takeLatest(actions.GET_ITEMS, GET_ITEMS),
    takeLatest(actions.REMOVE, REMOVE),
    takeLatest(actions.CLONE_COMBO, CLONE_COMBO),
  ])
}
