import apiFormDataClient from 'services/axios-formdata'
import apiClient from 'services/axios'

const BASE_URL = 'combo'

export async function createCombo({ payload }) {
  return apiFormDataClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updateCombo({ payload }) {
  return apiFormDataClient
    .put(`${BASE_URL}/${payload.id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteCombo(_id) {
  return apiClient
    .delete(`${BASE_URL}/${_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cloneCombo({ payload }) {
  return apiClient
    .post(`${BASE_URL}/${payload.id}/clone/`, {})
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getCombos() {
  return apiClient
    .get(`${BASE_URL}`, {})
    .then((response) => {
      response.data.combos.forEach((e) => {
        e.price = (e.price * 0.01).toFixed(2)
      })
      return { response }
    })
    .catch((error) => ({ error }))
}

export default {
  createCombo,
  updateCombo,
  deleteCombo,
  cloneCombo,
  getCombos,
}
