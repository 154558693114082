import apiClient from 'services/axios'

const BASE_URL = 'website-manager'

export async function savewebsitemanager(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getwebsitemanager() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function saveseo(payload) {
  return apiClient
    .post(`${BASE_URL}/seo`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function savepopupurl(payload) {
  return apiClient
    .put(`${BASE_URL}/website-popup`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function savegallery(payload) {
  return apiClient
    .post(`${BASE_URL}/gallery`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function savepopup(payload) {
  return apiClient
    .post(`${BASE_URL}/website-popup`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removepopup(popup_id) {
  return apiClient
    .delete(`${BASE_URL}/website-popup/?popup_id=${popup_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function delete_gallery_image(gallery_id) {
  return apiClient
    .delete(`${BASE_URL}/gallery/?gallery_id=${gallery_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function activewebsitepopup(payload) {
  return apiClient
    .put(`${BASE_URL}/website-popup`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function inactivewebsitepopup(payload) {
  return apiClient
    .put(`${BASE_URL}/website-popup`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function savemenu(payload) {
  return apiClient
    .post(`${BASE_URL}/menu`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function delete_menu_image(gallery_id, page) {
  return apiClient
    .delete(`${BASE_URL}/menu/?gallery_id=${gallery_id}&page=${page}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
