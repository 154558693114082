import actions from './actions'

const initialState = {
  reportSummary: null,
  timesheet_reports: [],
  timesheets: [],
  staffs: [],
  delivery_reports: [],
  data: null,
  selectedData: null,
  search: null,
  loading: false,
  creating: false,
  updating: false,
  removing: false,
  reportFromDate: null,
  reportToDate: null,
  reportdateRange: null,
  doordash_reports: [],
}

export default function reportsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
