const actions = {
  SET_STATE: 'combo/SET_STATE',
  CREATE_COMBO: 'combo/CREATE_COMBO',
  UPDATE_COMBO: 'combo/UPDATE_COMBO',
  GET_ITEMS: 'combo/GET_ITEMS',
  SHOW_CREATE: 'combo/SHOW_CREATE',
  HIDE_CREATE: 'combo/HIDE_CREATE',
  SHOW_UPDATE: 'combo/SHOW_UPDATE',
  HIDE_UPDATE: 'combo/HIDE_UPDATE',
  SHOW_DELETE: 'combo/SHOW_DELETE',
  HIDE_DELETE: 'combo/HIDE_DELETE',
  REMOVE: 'combo/REMOVE',
  CLONE_COMBO: 'combo/CLONE_COMBO',
  LOAD_ALL: 'combo/LOAD_ALL',
}

export default actions
