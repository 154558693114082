export async function getUserMenuData() {
  return [
    {
      title: 'Getting Started',
      key: 'getting_started',
      icon: 'fe fe-clipboard',
      url: '/getting-started',
      divider: true,
    },
    {
      title: 'Dashboard',
      key: 'dashboard',
      icon: 'fe fe-home',
      url: '/dashboard',
    },
    {
      title: 'Order History',
      key: 'order_history',
      icon: 'fa fa-history',
      url: '/order-history',
    },
    {
      title: 'Future Orders',
      key: 'future_orders',
      icon: 'fa fa-calendar',
      url: '/future-orders',
    },
    {
      title: 'Manage Customers',
      key: 'manage_customer',
      icon: 'fa fa-users',
      url: '/manage/customers',
    },
    {
      title: 'Reports',
      key: 'reports',
      icon: 'fa fa-columns',
      children: [
        {
          title: 'Sales Summary',
          key: 'sales_summary',
          name: 'sales_summary',
          url: '/reports/sales_summary',
        },
        {
          title: 'Timesheet Reports',
          key: 'timesheet',
          name: 'timesheet',
          url: '/reports/timesheet',
        },
        {
          title: 'In-House Driver Reports',
          key: 'delivery',
          name: 'delivery',
          url: '/reports/delivery',
        },
        {
          title: 'Doordash Driver Reports',
          key: 'doordash',
          name: 'doordash',
          url: '/reports/doordash',
        },
      ],
    },
    // {
    //   title: 'Manage Orders',
    //   key: 'manage-orders',
    //   icon: 'fa fa-cutlery',
    //   url: '/manage/orders',
    // },
    // {
    //   title: 'Order History',
    //   key: 'order-history',
    //   icon: 'fa fa-history',
    //   url: '/order-history',
    // },
    // {
    //   title: 'Menu Manager',
    //   key: 'manage-menu',
    //   icon: 'fe fe-menu',
    //   url: '/manage/menu',
    // },
    // {
    //   title: 'Manage Users',
    //   key: 'manage-users',
    //   icon: 'fe fe-user-check',
    //   url: '/manage/user',
    // },
    // {
    //   title: 'Manage Coupons',
    //   key: 'manage-coupons',
    //   icon: 'fe fe-tag',
    //   url: '/manage/coupon',
    // },
    // {
    //   category: true,
    //   title: 'Reports',
    //   key: 'reports',
    // },
    // {
    //   title: 'Sales Summary',
    //   key: 'sale-summary',
    //   icon: 'fe fe-file-text',
    //   url: '/summary/sale',
    // },
    // {
    //   category: true,
    //   title: 'Setup',
    //   key: 'setup',
    // },
    // {
    //   title: 'Store Setup',
    //   key: 'store-settings',
    //   icon: 'fe fe-shopping-bag',
    //   url: '/setup/store',
    // },
    // {
    //   title: 'Online Store Setup',
    //   key: 'order-settings',
    //   icon: 'fe fe-settings',
    //   url: '/settings/online-order',
    // },
    // {
    //   title: 'Delivery Zones',
    //   key: 'delivery-zones',
    //   icon: 'fa fa-edit',
    //   url: '/manage/delivery-zones',
    // },
    // {
    //   title: 'Integrations',
    //   key: 'integrations',
    //   icon: 'fe fe-settings',
    //   url: '/setup/integration',
    // },
    // {
    //   category: true,
    //   title: 'Website',
    //   key: 'website',
    // },
    {
      title: 'Online Ordering',
      key: 'online_ordering',
      icon: 'fa fa-cutlery',
      setBottom: true,
      children: [
        {
          title: 'Online Store Setup',
          name: 'online_order_setup',
          key: 'online_order_setup',
          url: '/settings/online-order',
        },
        {
          title: 'Online Delivery Zones',
          name: 'online-delivery-zones',
          key: 'online-delivery-zones',
          url: '/manage/delivery-zones',
        },
      ],
    },
    {
      title: 'Catering',
      key: 'catering',
      icon: 'fe fe-shopping-bag',
      setBottom: true,
      children: [
        {
          title: 'Catering Store Setup',
          name: 'catering_order_setup',
          key: 'catering_order_setup',
          url: '/settings/catering',
        },
        {
          title: 'Catering Delivery Zones',
          name: 'catering-delivery-zones',
          key: 'catering-delivery-zones',
          url: '/manage/catering-delivery-zones',
        },
      ],
    },
    {
      title: 'Settings',
      key: 'settings',
      icon: 'fe fe-settings',
      open: true,
      setBottom: true,
      children: [
        {
          title: 'Store Setup',
          name: 'store_setup',
          key: 'store_setup',
          url: '/setup/store',
        },
        {
          title: 'Menu Manager',
          key: 'menu_manager',
          name: 'menu_manager',
          url: '/manage/menu',
        },
        {
          title: 'Coupons',
          name: 'coupons',
          key: 'coupons',
          url: '/manage/coupon',
        },
        {
          title: 'Website Widget',
          name: 'website-widget',
          key: 'website-widget',
          url: '/settings/website-widget',
        },
        {
          title: 'Billing',
          name: 'billing',
          key: 'billing',
          url: '/settings/billing',
        },
        {
          title: 'Integrations',
          name: 'integration',
          key: 'integration',
          url: '/settings/integrations',
        },
        {
          title: 'Timesheets',
          name: 'timesheets',
          key: 'timesheets',
          url: '/manage/timesheets',
        },
        {
          title: 'Employees',
          name: 'employees',
          key: 'employees',
          url: '/manage/employees',
        },
        {
          title: 'Manage Floor Plan',
          name: 'reservation_floor_plan',
          key: 'reservation_floor_plan',
          url: '/reservation/floor-plan',
        },
        {
          title: 'Reservation Setup',
          name: 'reservation_setup',
          key: 'reservation_setup',
          url: '/reservation/setup',
        },
        {
          title: 'Manage Locations',
          name: 'manage-locations',
          key: 'manage-locations',
          url: '/manage/locations',
        },
        // {
        //   title: 'POS Setup',
        //   name: 'pos_setup',
        //   key: 'pos_setup',
        //   url: '/settings/pos',
        // },
      ],
    },
    {
      title: 'Website Manager',
      key: 'website-manager',
      icon: 'fe fe-globe',
      setBottom: true,
      children: [
        {
          title: 'Website Setup',
          name: 'website-setup',
          key: 'website-setup',
          url: '/website-manager/website-setup',
        },
        {
          title: 'Social Media',
          name: 'social-media',
          key: 'social-media',
          url: '/website-manager/social-media',
        },
        {
          title: 'Gallery',
          name: 'gallery',
          key: 'gallery',
          url: '/website-manager/gallery',
        },
        {
          title: 'Website Popup',
          name: 'website-popup',
          key: 'website-popup',
          url: '/website-manager/website-popup',
        },
      ],
    },
  ]
}

export function getBusinessMenuData() {
  return [
    {
      title: 'Branding',
      key: 'branding',
      icon: 'fe fe-shopping-bag',
      url: '/setup/branding',
    },
    {
      title: 'Manage Taxes',
      key: 'manage-taxes',
      icon: 'fe fe-percent',
      url: '/manage/tax',
    },
    {
      title: 'Manage Locations',
      key: 'manage-location',
      icon: 'fe fe-map',
      url: '/manage/location',
    },
    {
      category: true,
      title: 'Global Reports',
      key: 'global-reports',
    },
    {
      title: 'Sales Summary',
      key: 'sale-summary',
      icon: 'fe fe-file-text',
      url: '/summary/sale',
    },
  ]
}
