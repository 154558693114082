const actions = {
  SET_STATE: 'employee/SET_STATE',
  SHOW_CREATE: 'employee/SHOW_CREATE',
  HIDE_CREATE: 'employee/HIDE_CREATE',
  SHOW_UPDATE: 'employee/SHOW_UPDATE',
  HIDE_UPDATE: 'employee/HIDE_UPDATE',
  SHOW_DELETE: 'employee/SHOW_DELETE',
  HIDE_DELETE: 'employee/HIDE_DELETE',
  REMOVE_DATA: 'employee/REMOVE_DATA',
  SET_ERROR_STATE: 'employee/SET_ERROR_STATE',

  LOAD_ALL: 'employee/LOAD_ALL',
  CREATE: 'employee/CREATE',
  UPDATE: 'employee/UPDATE',
  REMOVE: 'employee/REMOVE',
}

export default actions
