import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  getModifierGroups,
  updateModifierGroup,
  deleteModifierGroup,
  createModifierGroup,
  cloneModifier,
} from 'services/modifier-group'
import store from 'store'
import actions from './actions'

export function* CREATE_MODIFIER_GROUP(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(createModifierGroup, payload)

  if (status?.response?.data) {
    const business = store.get('user.business')
    // const location = store.get('user.location')
    const getCatPayload = {
      payload: {
        business_id: business._id,
        // location_id: location._id,
      },
    }
    const { response } = yield call(getModifierGroups, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.modifier_groups,
        displayAddDrawer: false,
        creating: false,
      },
    })
  }

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* UPDATE_MODIFIER_GROUP(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(updateModifierGroup, payload)

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const getCatPayload = {
    payload: {
      business_id: business._id,
      // location_id: location._id,
    },
  }

  if (status?.response?.data) {
    const { response } = yield call(getModifierGroups, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.modifier_groups,
        displayUpdateDrawer: false,
        updating: false,
      },
    })
  }
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

export function* GET_MODIFIER_GROUPS(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      listing: true
    },
  })

  const { response } = yield call(getModifierGroups, payload)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      listing: false,
      data: response.data.modifier_groups,
    },
  })
}

export function* REMOVE(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })

  const status = yield call(deleteModifierGroup, payload.payload._id)
  console.log(status)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })

  const business = store.get('user.business')
  // const location = store.get('user.location')
  const getCatPayload = {
    payload: {
      business_id: business._id,
      // location_id: location._id,
    },
  }

  if (status.response.data.message) {
    const { response } = yield call(getModifierGroups, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.modifier_groups,
        displayRemoveModal: false,
        removing: false,
      },
    })
  }
}

export function* CLONE_MODIFIER(payload) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })

  const status = yield call(cloneModifier, payload)

  if (status?.response?.data) {
    const business = store.get('user.business')
    // const location = store.get('user.location')
    const getCatPayload = {
      payload: {
        business_id: business._id,
        // location_id: location._id,
      },
    }
    const { response } = yield call(getModifierGroups, getCatPayload)
    yield put({
      type: actions.SET_STATE,
      payload: {
        loading: false,
        data: response.data.modifier_groups,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.CREATE_MODIFIER_GROUP, CREATE_MODIFIER_GROUP),
    takeLatest(actions.UPDATE_MODIFIER_GROUP, UPDATE_MODIFIER_GROUP),
    takeLatest(actions.GET_MODIFIER_GROUPS, GET_MODIFIER_GROUPS),
    takeLatest(actions.CLONE_MODIFIER, CLONE_MODIFIER),
    takeLatest(actions.REMOVE, REMOVE),
  ])
}
