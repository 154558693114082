import apiClient from 'services/axios'

const BASE_URL = 'subscription'

export async function listPlans(payload) {
  return apiClient
    .get(`${BASE_URL}/plan`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getPlan(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function changePlan(payload) {
  const { is_update } = payload
  delete payload.is_update;
  if (is_update) {
    return apiClient
      .put(`${BASE_URL}`, payload)
      .then((response) => ({ response }))
      .catch((error) => ({ error }))
  }
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}


export async function listInvoices() {
  return apiClient
    .get(`invoice`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}