import { all, call, put, takeLatest } from 'redux-saga/effects'
import load from 'api/country'
import actions from './actions'

export function* LOAD_ALL() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
      loading: true,
    },
  })
  const { response } = yield call(load)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { countries } = response.data
    countries.forEach((country) => {
      country.states.forEach((state) => {
        state.country_id = country._id
      })
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: countries,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL)])
}
