import { all, call, put, takeLatest } from 'redux-saga/effects'
import { load } from 'api/integration'
import actions from './actions'

export function* LOAD() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      data: null,
      loading: true,
      external_location_id: null,
      is_import_clover_available: false,
    },
  })
  const { response } = yield call(load)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { integration, external_location_id, is_import_clover_available, images } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: integration,
        images,
        external_location_id,
        is_import_clover_available,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD, LOAD)])
}
