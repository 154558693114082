import actions from './actions'

export const initialState = {
  currentIndex: 100,
  radius_val: 1500,
  square_coordinates: [],
  reducerSquareCoordinate: [],
  deliveryZones: [],
  locations: {
    coordinates: null,
  },
  mapcenter: null,
  center: null,
  lng: null,
  lat: null,
  color: null,
  new_color_val: null,
  newRadius: null,
  _id: null,
  openPanel: null,
  showAddZOne: true,
  tempId: null,
  tempIdIndex: null,
  circleRowId: null,
  circleradius: null,
  delivery_zone_data: null,
  data: null,
  selecteddata: null,
  selecteddataid: null,
  drawingFlag: true,
  displayColorPicker: false,
  createMapModal: false,
  removing: false,
  updating: false,
  saving: false,
  address: null,
  latitude: null,
  longitude: null,
}

export default function deliveryradiusReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action?.payload }
    case actions.UPDATE_FORM_VALUES: {
      const index = state?.deliveryZones.findIndex((dzone) => dzone?._id === state?.tempIdIndex)
      const newArray = [...state?.deliveryZones]
      newArray[index].delivery_minimum_amount = action?.payload?.delivery_minimum_amount
      newArray[index].delivery_rate = action?.payload?.delivery_rate
      newArray[index].name = action?.payload?.name
      newArray[index].allow_free_delivery = action?.payload?.allow_free_delivery
      newArray[index].free_delivery_order_value = action?.payload?.free_delivery_order_value
      newArray[index].taxes = action?.payload?.taxes
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    case actions.UPDATE_ADDRESS_VALUES: {
      const index = state?.deliveryZones.findIndex((dzone) => dzone?._id === state?.tempIdIndex)
      const newArray = [...state?.deliveryZones]
      newArray[index].address = action?.payload?.address
      newArray[index].locations = action?.payload?.locations
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    case actions.UPDATE_MAP_SHAPE: {
      const index = state?.deliveryZones.findIndex((dzone) => dzone?._id === state?.tempIdIndex)
      const newArray = [...state?.deliveryZones]
      const newMap = state?.deliveryZones[index]?.newMap
      if (action?.payload?.map_shape === 'circle' && newMap === true) {
        state.mapcenter = state.mapcenter
        newArray[index].radius = state?.newRadius
      } else if (action?.payload?.map_shape === 'circle') {
        state.mapcenter = state.mapcenter
        newArray[index].radius = state?.newRadius + 1000
        newArray[index].locations.coordinates = state?.deliveryZones[index].locations.coordinates
      } else if (action?.payload?.map_shape === 'square') {
        state.mapcenter = state.mapcenter
        state.square_coordinates = state.reducerSquareCoordinate
        newArray[index].locations.coordinates = state?.reducerSquareCoordinate
        newArray[index].newMapType = 'Update'
        newArray[index].radius = 0
        newArray[index].newMap = true
      } else if (action?.payload?.map_shape === 'custom') {
        state.mapcenter = state.mapcenter
        newArray[index].radius = 0
        newArray[index].newMap = true
        newArray[index].locations.coordinates = state?.deliveryZones[index].locations.coordinates
      } else if (action?.payload?.map_shape === 'custom' && newMap === true) {
        state.mapcenter = state.mapcenter
        newArray[index].radius = 0
        newArray[index].newMap = true
        newArray[index].locations.coordinates = state?.deliveryZones[index].locations.coordinates
      }
      newArray[index].map_shape = action?.payload?.map_shape
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    case actions.UPDATE_CIRCLE_CENTER: {
      const index = state.deliveryZones.findIndex(
        (dzone) => dzone._id === action.payload.circleRowId,
      )
      const newArray = [...state.deliveryZones]
      newArray[index].locations.coordinates = [
        [[action.payload.center.lng, action.payload.center.lat]],
      ]
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    case actions.UPDATE_CIRCLE_RADIUS: {
      const index = state.deliveryZones.findIndex(
        (dzone) => dzone._id === action.payload.circleRowId,
      )
      const newArray = [...state.deliveryZones]
      const radius = Math.floor(action.payload.radius_val)
      newArray[index].radius = radius
      newArray[index].locations.coordinates = [
        [[action.payload.center.lng, action.payload.center.lat]],
      ]
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    case actions.UPDATE_POLYGON_COORD: {
      const index = state.deliveryZones.findIndex(
        (dzone) => dzone._id === action.payload.currentIndex,
      )
      const newArray = [...state.deliveryZones]
      newArray[index].locations.coordinates = state.square_coordinates
      return {
        ...state,
        deliveryZones: newArray,
      }
    }
    default:
      return state
  }
}
