import apiClient from 'services/axios'

const BASE_URL = 'reservations-settings'

export async function reservation_setup() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function updateReservationSetup(payload) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addReservationHour(payload) {
  return apiClient
    .post(`${BASE_URL}/hours`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editReservationHour(hourId, payload) {
  return apiClient
    .put(`${BASE_URL}/hours?hours_id=${hourId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removeReservationHour(hourId) {
  return apiClient
    .delete(`${BASE_URL}/hours?hours_id=${hourId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function addBlockedDate(payload) {
  return apiClient
    .post(`${BASE_URL}/block-date`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function editBlockedDate(BlockedDateId, payload) {
  return apiClient
    .put(`${BASE_URL}/block-date?block_date_id=${BlockedDateId}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function removeBlockedDate(BlockedDateId) {
  return apiClient
    .delete(`${BASE_URL}/block-date?block_date_id=${BlockedDateId}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}
