import apiClient from 'services/axios'

const BASE_SALES_URL = 'order/sales_summary'
const BASE_TIMESHEET_URL = 'timesheet'
const BASE_STAFF_URL = 'staff'
const BASE_URL = 'order'

export async function getsalesreport(payload) {
  const orderfromdate = payload.payload.from
  const ordertodate = payload.payload.to
  const orderdaterange = payload.payload.date_range
  return apiClient
    .get(`${BASE_SALES_URL}?date_range=${orderdaterange}&from=${orderfromdate}&to=${ordertodate}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getTimesheetReports(payload) {
  const orderfromdate = payload.payload.from || ''
  const ordertodate = payload.payload.to || ''
  const staff_id = payload.payload.staff_id || ''
  return apiClient
    .get(
      `${BASE_TIMESHEET_URL}/report?staff_id=${staff_id}&from=${orderfromdate}&to=${ordertodate}`,
    )
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getTimesheets(payload) {
  const orderdate = payload.payload.date
  return apiClient
    .get(`${BASE_TIMESHEET_URL}?date=${orderdate}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function createTimesheets(payload) {
  return apiClient
    .post(`${BASE_TIMESHEET_URL}/add`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteTimesheet(payload) {
  const { id } = payload.payload
  return apiClient
    .delete(`${BASE_TIMESHEET_URL}/${id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getStaffs() {
  return apiClient
    .get(`${BASE_STAFF_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getDeliveryReports(payload) {
  const orderfromdate = payload.payload.from || ''
  const ordertodate = payload.payload.to || ''
  const staff_id = payload.payload.staff_id || ''
  return apiClient
    .get(
      `${BASE_STAFF_URL}/delivery/report?staff_id=${staff_id}&from=${orderfromdate}&to=${ordertodate}`,
    )
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getDoordashReports(payload) {
  const orderfromdate = payload.payload.from || ''
  const ordertodate = payload.payload.to || ''
  return apiClient
    .get(`${BASE_URL}/doordash/report?from=${orderfromdate}&to=${ordertodate}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default getsalesreport
