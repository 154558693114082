const actions = {
  SET_STATE: 'locations/SET_STATE',
  SHOW_CREATE: 'locations/SHOW_CREATE',
  HIDE_CREATE: 'locations/HIDE_CREATE',
  SHOW_UPDATE: 'locations/SHOW_UPDATE',
  HIDE_UPDATE: 'locations/HIDE_UPDATE',
  SHOW_DELETE: 'locations/SHOW_DELETE',
  HIDE_DELETE: 'locations/HIDE_DELETE',
  REMOVE_DATA: 'locations/REMOVE_DATA',
  SET_ERROR_STATE: 'locations/SET_ERROR_STATE',

  LOAD_ALL: 'locations/LOAD_ALL',
  CREATE: 'locations/CREATE',
  UPDATE: 'locations/UPDATE',
  REMOVE: 'locations/REMOVE',

  SEARCH_DATA: 'locations/SEARCH_DATA',
  SHOW_SEARCH: 'locations/SHOW_SEARCH',
  EMAIL_VERIFY: 'locations/EMAIL_VERIFY',
}

export default actions
