import apiClient from 'services/axios'

const BASE_URL = 'integration'

export async function load() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default load