import { all, call, put, takeLatest } from 'redux-saga/effects'
import { addzone, updatezone, deletezone } from 'api/deliveryradius'
import { store_info } from 'api/setting'
import actions from './actions'

export function* GET_DELIVERY_ZONES() {
  const { response } = yield call(store_info)
  if (response) {
    const apiMapCenter = response?.data?.location?.address?.geo_location?.coordinates || []
    const MapCenterCoordinates = []
    MapCenterCoordinates.push({
      lng: apiMapCenter[0],
      lat: apiMapCenter[1],
    })
    const MapCenterobject = MapCenterCoordinates[0]
    yield put({
      type: actions.SET_STATE,
      payload: {
        showAddZOne: true,
        mapcenter: MapCenterobject,
        center: MapCenterobject,
        locations: {
          coordinates: [[[apiMapCenter[0], apiMapCenter[1]]]],
        },
        deliveryZones: response?.data?.location?.delivery?.delivery_zones.map(e => {
          e.delivery_rate = (e.delivery_rate * 0.01).toFixed(2);
          e.delivery_minimum_amount = (e.delivery_minimum_amount * 0.01).toFixed(2);
          e.free_delivery_order_value = (e.free_delivery_order_value * 0.01).toFixed(2);
          return e;
        }),
      },
    })
  }
}

export function* ADD_ZONE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: true,
    },
  })
  const { response } = yield call(addzone, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      saving: false,
    },
  })
  if (response) {
    const apiMapCenter = response?.data?.location?.address?.geo_location?.coordinates
    const MapCenterCoordinates = []
    MapCenterCoordinates.push({
      lng: apiMapCenter[0],
      lat: apiMapCenter[1],
    })
    const MapCenterobject = MapCenterCoordinates[0]
    yield put({
      type: actions.SET_STATE,
      payload: {
        deliveryZones: response?.data?.location?.delivery?.delivery_zones.map(e => {
          e.delivery_rate = (e.delivery_rate * 0.01).toFixed(2);
          e.delivery_minimum_amount = (e.delivery_minimum_amount * 0.01).toFixed(2);
          e.free_delivery_order_value = (e.free_delivery_order_value * 0.01).toFixed(2);
          return e;
        }),
        showAddZOne: true,
        new_color_val: null,
        openPanel: null,
        currentIndex: 100,
        mapcenter: MapCenterobject,
        center: MapCenterobject,
        locations: {
          coordinates: [[[apiMapCenter[0], apiMapCenter[1]]]],
        },
      },
    })
  }
}

export function* UPDATE_ZONE({ payload }) {
  const del_id = payload.delivery_id
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updatezone, payload, del_id)
  if (response) {
    const apiMapCenter = response?.data?.location?.address?.geo_location?.coordinates
    const MapCenterCoordinates = []
    MapCenterCoordinates.push({
      lng: apiMapCenter[0],
      lat: apiMapCenter[1],
    })
    const MapCenterobject = MapCenterCoordinates[0]
    yield put({
      type: actions.SET_STATE,
      payload: {
        deliveryZones: response?.data?.location?.delivery?.delivery_zones.map(e => {
          e.delivery_rate = (e.delivery_rate * 0.01).toFixed(2);
          e.delivery_minimum_amount = (e.delivery_minimum_amount * 0.01).toFixed(2);
          e.free_delivery_order_value = (e.free_delivery_order_value * 0.01).toFixed(2);
          return e;
        }),
        openPanel: null,
        new_color_val: null,
        currentIndex: 100,
        showAddZOne: true,
        mapcenter: MapCenterobject,
        center: MapCenterobject,
        locations: {
          coordinates: [[[apiMapCenter[0], apiMapCenter[1]]]],
        },
        updating: false,
      },
    })
  }
}

export function* DELETE_ZONE(payload) {
  const del_id = payload.payload._id
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { response } = yield call(deletezone, del_id)
  if (response) {
    const apiMapCenter = response?.data?.location?.address?.geo_location?.coordinates
    const MapCenterCoordinates = []
    MapCenterCoordinates.push({
      lng: apiMapCenter[0],
      lat: apiMapCenter[1],
    })
    const MapCenterobject = MapCenterCoordinates[0]
    yield put({
      type: actions.SET_STATE,
      payload: {
        deliveryZones: response?.data?.location?.delivery?.delivery_zones.map(e => {
          e.delivery_rate = (e.delivery_rate * 0.01).toFixed(2);
          e.delivery_minimum_amount = (e.delivery_minimum_amount * 0.01).toFixed(2);
          e.free_delivery_order_value = (e.free_delivery_order_value * 0.01).toFixed(2);
          return e;
        }),
        removing: false,
        openPanel: null,
        new_color_val: null,
        currentIndex: 100,
        showAddZOne: true,
        mapcenter: MapCenterobject,
        center: MapCenterobject,
        locations: {
          coordinates: [[[apiMapCenter[0], apiMapCenter[1]]]],
        },
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.ADD_ZONE, ADD_ZONE),
    takeLatest(actions.UPDATE_ZONE, UPDATE_ZONE),
    takeLatest(actions.DELETE_ZONE, DELETE_ZONE),
    takeLatest(actions.GET_DELIVERY_ZONES, GET_DELIVERY_ZONES),
  ])
}
