import actions from './actions'

const initialState = {
  data: {
    new: {
      name: 'New',
      items: [],
    },
    confirmedAsap: {
      name: 'Confirmed (Now)',
      items: [],
    },
    confirmedUpcoming: {
      name: 'Confirmed (Upcoming)',
      items: [],
    },
    readyForPickup: {
      name: 'Ready for Pickup',
      items: [],
    },
    onDelivery: {
      name: 'On Delivery',
      items: [],
    },
    completed: {
      name: 'Completed',
      items: [],
    },
  },
  selectedData: null,
  search: null,
  loading: false,

  displayAddDrawer: false,
  creating: false,

  displayUpdateDrawer: false,
  updating: false,

  displayRemoveModal: false,
  removing: false,
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_UPDATE:
      return { ...state, selectedData: action.payload.order, displayUpdateDrawer: true }
    case actions.HIDE_UPDATE:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    default:
      return state
  }
}
