import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { load, remove, block } from 'api/customer'
import actions from './actions'

export function* LOAD() {
  const { response } = yield call(load)
  if (response) {
    const { customers } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: customers,
      },
    })
  }
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
      displayRemoveModal: false,
    },
  })
  yield call(LOAD)
}

export function* BLOCK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      blocking: true,
    },
  })
  const { _id } = payload
  yield call(block, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      blocking: false,
      displayBlockModal: false,
    },
  })
  yield call(LOAD)
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD, LOAD),
    takeEvery(actions.REMOVE, REMOVE),
    takeEvery(actions.BLOCK, BLOCK),
  ])
}
