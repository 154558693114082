import apiClient from 'services/axios'

const BASE_URL = 'modifier-group'

export async function createModifierGroup({ payload }) {
  return apiClient
    .post(`${BASE_URL}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function getModifierGroups() {
  return apiClient
    .get(`${BASE_URL}`)
    .then((response) => {
      response.data.modifier_groups.forEach(e => {
        e.options.forEach(ee => {
          ee.price = (ee.price * 0.01).toFixed(2);
        })
      })
      return { response };
    })
    .catch((error) => ({ error }))
}

export async function updateModifierGroup({ payload }) {
  console.log('payload', payload)
  const { id } = payload;
  delete payload.id;
  return apiClient
    .put(`${BASE_URL}/${id}`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function deleteModifierGroup(_id) {
  return apiClient
    .delete(`${BASE_URL}/${_id}`)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export async function cloneModifier({ payload }) {
  const { id } = payload;
  delete payload.id;

  return apiClient
    .post(`${BASE_URL}/${id}/clone`, payload)
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export default {
  createModifierGroup,
  getModifierGroups,
  updateModifierGroup,
  deleteModifierGroup,
  cloneModifier,
}
